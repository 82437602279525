import Query from "./Query";
import moment from "moment";
class User {
    constructor() {
        this.api = new Query();
    }

    sendCode(phone) {
        return this.api.query("/confirm_phone", {
            phone: phone,
            auth_type: "whats_app",
        }, "POST");
    }

    confirmPhone(phone, confirmationCode = "") {
        return this.api.query("/confirm_phone", {
            phone: phone,
            confirmation_code: confirmationCode,
            auth_type: "whats_app",
        }, "POST");
    }

    setPassword(phone, password, passToken, lastName, name, secondName, email, sex, birthday) {
        return this.api.query("/reg_and_auth_client", {
            phone: phone,
            password: password,
            pass_token: passToken,
            last_name: lastName,
            name: name,
            second_name: secondName,
            email: email,
            birthday: birthday,
            sex: sex,
        }, "POST");
    }

    authClient(phone, password) {
        return this.api.query("/auth_client", {
            phone: phone,
            password: password,
        }, "POST");
    }

    checkUserToken(token) {
        return this.api.query("/client", {}, "GET", token);
    }

    getAuthUserData(token) {
        return this.api.query("/client", {}, "GET", token);
    }

    getAuthUserHistory(token) {
        return this.api.query("/visits_history", {}, "GET", token);
    }

    getAuthUserDepo(token) {
        return this.api.query("/deposits", {}, "GET", token);
    }

    getAuthUserBookings(token, statuses = ["planned"]) {
        return this.api.query("/appointments", {
            statuses: JSON.stringify(statuses),
        }, "GET", token);
    }

    bookingAuthUser(token, id) {
        return this.api.query("/client_to_class", {
            appointment_id: id,
        }, "POST", token);
    }

    bookingOutAuthUser(token, id) {
        return this.api.query("/client_from_class", {
            appointment_id: id,
        }, "DELETE", token);
    }
}

export default User;