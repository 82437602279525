import Query from "./Query";

class ClubStatic {
    constructor() {
        this.api = new Query();
    }

    getClubWorkload(clubId) {
        return this.api.query("/club_workload", {club_id: clubId});
    }
}

export default ClubStatic;