import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import User from "../../Api/User";
import moment from "moment";
import {IMaskInput} from "react-imask";
import CMS from "../../Api/CMS";

const LoginRegPage = () => {
    const UserApi = new User();
    const CMSAPI = new CMS();
    const navigate = useNavigate();
    const [frame, setFrame] = useState("login");
    const [isLoading, setIsLoading] = useState(false);
    const [regStatus, setRegStatus] = useState("inputPhone");
    const [recoveryStatus, setRecoveryStatus] = useState("inputPhone");
    const [phone, setPhone] = useState("");
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [lastName, setLastName] = useState("");
    const [name, setName] = useState("");
    const [secondName, setSecondName] = useState("");
    const [email, setEmail] = useState("");
    const [birthday, setBirthday] = useState(moment(Date.now()).format("YYYY-MM-DD"));
    const [sex, setSex] = useState(1);
    const [error, setError] = useState("");

    useEffect(() => {
        UserApi.checkUserToken(localStorage.getItem("userToken")).then((d) => {
            if (d.data.result) {
                navigate("/lk");
            }
        });
    });

    const sendCode = () => {
        setIsLoading(true);
        UserApi.sendCode(phone).then(() => {
            setRegStatus("sendCode");
            setRecoveryStatus("sendCode");
            setError("");
            setIsLoading(false);
        }).catch((e) => {
            setIsLoading(false);
            setError(e.response.data.error_message)
        });
    };

    const changePassword = () => {
        setIsLoading(true);
        UserApi.confirmPhone(phone, code).then((d) => {
            setError("");
            setIsLoading(false);
            if (d.data.data.pass_token) {
                UserApi.setPassword(phone, password, d.data.data.pass_token, lastName, name, secondName, email, sex, birthday).then((d) => {
                    if (d.data.result) {
                        setRegStatus("ok");
                        setRecoveryStatus("ok");
                        document.location.reload();
                    }
                    setError("");
                }).catch((e) => {
                    setError(e.response.data.error_message)
                });
            }
        }).catch((e) => {
            setIsLoading(false);
            setError(e.response.data.error_message)
        });
    };

    const loginUser = () => {
        setIsLoading(true);
        UserApi.authClient(phone, password).then((d) => {
            console.log(d.data)
            UserApi.getAuthUserData(d.data.data.user_token).then((user) => {
                CMSAPI.queryContentByFilter("clients", {phone: user.data.data.phone.replace("+", "\+")}).then((clients) => {
                    console.log(clients.data);
                    if (clients.data.length === 0) {
                        console.log("client not found");
                        CMSAPI.addItem("clients", {
                            phone: user.data.data.phone.replace("+", "\+"),
                            email: user.data.data.email,
                            name: user.data.data.name + " " + user.data.data.second_name,
                        }, () => {
                            localStorage.setItem("userToken", d.data.data.user_token);
                            console.log("LOGIN OK");
                            window.location.href = "/lk";
                            setError("");
                            setIsLoading(false);
                        }).then(() => {

                        });
                    } else {
                        localStorage.setItem("userToken", d.data.data.user_token);
                        console.log("LOGIN OK");
                        window.location.href = "/lk";
                        setError("");
                        setIsLoading(false);
                    }
                });

            });
        }).catch((e) => {
            setIsLoading(false);
            setError(e.response.data.error_message)
        });
    };

    return <div className="page center">
        <br/><br/><br/>
        <div className="contentCenter">
            {
                frame === "login"
                    ? <div className="frame">
                        <p className="title">Вход в аккаунт</p>
                        <div className="input">
                            <IMaskInput mask={'+{7}(000) 000-00-00'} autoComplete="field1" id="field1" placeholder="Номер телефона"
                                        onChange={(e) => setPhone(e.target.value.replace("+", "").replace("(", "").replace(")", "").replaceAll("-", "").replaceAll(" ", ""))}/>
                        </div>
                        <div className="input">
                            <input placeholder="Пароль" autoComplete="off" id="pass" onChange={(e) => setPassword(e.target.value)}
                                   type="password"/>
                        </div>
                        {
                            error !== ""
                                ? <p className="error">ОШИБКА! {error}</p>
                                : null
                        }
                        {
                            !isLoading
                                ? <button onClick={loginUser}>Вход</button>
                                : <button>Загрузка...</button>
                        }
                        <p>Забыли пароль? <a href="#" onClick={() => setFrame("recovery")}>Восстановить доступ</a></p>
                        <p>Нет аккаунта? <a href="#" onClick={() => setFrame("reg")}>Регистрация аккаунта</a></p>
                    </div>
                    : null
            }
            {
                frame === "reg"
                    ? <div className="frame">
                        <p className="title">Регистрация</p>
                        {
                            regStatus === "inputPhone"
                                ? <>
                                    <div className="input">
                                        <IMaskInput mask={'+{7}(000) 000-00-00'} autocomplete="off" placeholder="Номер телефона"
                                                    onChange={(e) => setPhone(e.target.value.replace("+", "").replace("(", "").replace(")", "").replaceAll("-", "").replaceAll(" ", ""))}/>
                                    </div>
                                    <p>Код прийдет на WhatsApp</p>
                                    <br/>
                                </>
                                : null
                        }
                        {
                            regStatus === "sendCode"
                                ? <div>
                                    <div className="input"><input autoComplete="off" id="code" placeholder="Код из WhatsApp"
                                                                  onChange={(e) => setCode(e.target.value)} type="text"/></div>
                                    <div className="input"><input autoComplete="off" placeholder="Новый пароль"
                                                                  onChange={(e) => setPassword(e.target.value)}
                                                                  type="password"/></div>
                                    <div className="input"><input placeholder="Имя"
                                                                  onChange={(e) => setLastName(e.target.value)}
                                                                  type="text"/></div>
                                    <div className="input"><input placeholder="Фамилия"
                                                                  onChange={(e) => setName(e.target.value)} type="text"/></div>
                                    <div className="input"><input placeholder="Отчество"
                                                                  onChange={(e) => setSecondName(e.target.value)}
                                                                  type="text"/></div>
                                    <div className="input"><input placeholder="Email" onChange={(e) => setEmail(e.target.value)}
                                                                  type="text"/></div>
                                    <div className="input"><label htmlFor="start">День рождения</label><input
                                        onChange={(e) => setBirthday(e.target.value)} type="date" id="start" name="trip-start"
                                        defaultValue={moment(Date.now()).format("YYYY-MM-DD")}
                                        min="1900-01-01" max="2023-01-01"/></div>
                                    <p>Пол:</p>
                                    <input type="checkbox" value="1" onChange={() => setSex(1)} checked={sex === 1}/><label
                                    htmlFor="">М</label>
                                    <input type="checkbox" value="2" onChange={() => setSex(2)} checked={sex === 2}/><label
                                    htmlFor="">Ж</label>
                                </div>
                                : null
                        }
                        {
                            regStatus === "ok"
                                ? <p>ОК! Ваш аккаунт создан</p>
                                : null
                        }
                        {
                            error !== ""
                                ? <p className="error">ОШИБКА! {error}</p>
                                : null
                        }
                        {
                            !isLoading
                                ? <button
                                    onClick={regStatus === "inputPhone" ? () => sendCode() : () => changePassword()}>{regStatus === "inputPhone" ? "Отправить код" : "Регистрация"}</button>
                                : <button>Загрузка...</button>
                        }
                        <p>Есть аккаунт? <a href="#" onClick={() => setFrame("login")}>Сделайте вход</a></p>
                    </div>
                    : null
            }
            {
                frame === "recovery"
                    ? <div className="frame">
                        <p className="title">Восстановление доступа</p>
                        {
                            recoveryStatus === "inputPhone"
                                ? <>
                                    <div className="input">
                                        <IMaskInput  autocomplete="off" mask={'+{7}(000) 000-00-00'} placeholder="Номер телефона"
                                                    onChange={(e) => setPhone(e.target.value.replace("+", "").replace("(", "").replace(")", "").replaceAll("-", "").replaceAll(" ", ""))}/>
                                    </div>
                                    <p>Код прийдет на WhatsApp</p>
                                    <br/>
                                </>
                                : null
                        }
                        {
                            recoveryStatus === "sendCode"
                                ? <div>
                                    <div className="input"><input autoComplete="off" id="id" placeholder="Код из WhatsApp"
                                                                  onChange={(e) => setCode(e.target.value)} type="text"/></div>
                                    <div className="input"><input autoComplete="off" placeholder="Новый пароль"
                                                                  onChange={(e) => setPassword(e.target.value)}
                                                                  type="password"/></div>
                                </div>
                                : null
                        }
                        {
                            recoveryStatus === "ok"
                                ? <p>ОК! Новый пароль задан</p>
                                : null
                        }
                        {
                            error !== ""
                                ? <p className="error">ОШИБКА! {error}</p>
                                : null
                        }
                        {
                            !isLoading
                                ? <button
                                    onClick={recoveryStatus === "inputPhone" ? () => sendCode() : () => changePassword()}>{recoveryStatus === "inputPhone" ? "Отправить код" : "Сохранить"}</button>
                                : <button>Загрука...</button>
                        }
                        <p>Вспомнили пароль? <a href="#" onClick={() => setFrame("login")}>Сделайте вход</a></p>
                    </div>
                    : null
            }
        </div>
    </div>
};

export default LoginRegPage;