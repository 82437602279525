import React, {useCallback, useEffect, useState} from "react";
import {useNavigate, Link} from "react-router-dom";
import Select from "../../Atoms/Select/Select";
import ClubStatic from "../../../Api/ClubStatic";
import User from "../../../Api/User";

import HiddenPhoneNumber from "../../Molecules/Contacts/HiddenPhoneNumber/HiddenPhoneNumber";

import LogoImage from "../../../Images/Logo.svg";
import {ReactComponent as OnlineSvgImage} from "../../../Images/Icons/Online.svg";
import {ReactComponent as WhatsAppImage} from "../../../Images/Social/WhatsApp.svg";
import {ReactComponent as TelegramImage} from "../../../Images/Social/Telegram.svg";
import {ReactComponent as VKImage} from "../../../Images/Social/VK.svg";
import BalanceImage from "../../../Images/Icons/Balance.svg";
import MenuSlideImage from "../../../Images/Icons/MenuSlide.svg";
import FingerprintImage from "../../../Images/Icons/Fingerprint.svg";
import moment from "moment";
import {IMaskInput} from "react-imask";
import {useDispatch, useSelector} from "react-redux";
import {login, logout} from "../../../Store/authSlice";
import CMS from "../../../Api/CMS";

const SvgMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback((e) => {
        const withinBoundaries = e.composedPath().includes(document.querySelector(".svgMenu"));

        if (!withinBoundaries) {
            setIsOpen(false);
        } else {
            const withinBoundariesSpan = e.composedPath().includes(document.querySelector(".svgMenu span"));
            if (withinBoundariesSpan) {
                setIsOpen(!isOpen);
            }
        }
    }, [setIsOpen, isOpen]);

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick)
        };
    }, [handleClick]);

    return <button className={`svgMenu openerSvgMenu ${isOpen ? "open" : null}`}>
        <span className="openerSvgMenu">М<img className="menuSlideImage openerSvgMenu" src={MenuSlideImage}
                                              alt=""/>НЮ</span>
        <div className="menu">
            <Link className="link" to="/groupTimes">Расписание</Link>
            <Link className="link" to="/trainers">Тренерский состав</Link>
            <Link className="link" to="/plans">Абонементы</Link>
            {/*<Link className="link" to="/forNovice">Для новичка</Link>*/}
            <Link className="link" to="/promotions">Акции</Link>
            <Link className="link" to="/about">О клубе</Link>
            <Link className="link" to="/contacts">Контакты</Link>
        </div>
    </button>
};

const LKButtonMenu = (props) => {
    const UserApi = new User();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [frame, setFrame] = useState("login");
    const [isLoadingLogReg, setIsLoadingLogReg] = useState(false);
    const [regStatus, setRegStatus] = useState("inputPhone");
    const [recoveryStatus, setRecoveryStatus] = useState("inputPhone");
    const [phone, setPhone] = useState("");
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [lastName, setLastName] = useState("");
    const [name, setName] = useState("");
    const [secondName, setSecondName] = useState("");
    const [email, setEmail] = useState("");
    const [birthday, setBirthday] = useState(moment(Date.now()).format("YYYY-MM-DD"));
    const [sex, setSex] = useState(1);
    const [error, setError] = useState("");
    const [widthLoginButton, setWidthLoginButton] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [userToken, setUserToken] = useState(localStorage.getItem("userToken") ? localStorage.getItem("userToken") : "");
    // const [userData, setUserData] = useState({});
    const userData = useSelector(state => state.auth.userData);

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback((e) => {
        const withinBoundaries = e.composedPath().includes(document.querySelector(".primaryMenu"));

        if (!withinBoundaries) {
            setIsOpen(false);
        } else {
            const withinBoundariesSpan = e.composedPath().includes(document.querySelector("button.primary.login"));
            if (withinBoundariesSpan) {
                setIsOpen(!isOpen);
            }
        }
    }, [setIsOpen, isOpen]);

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick)
        };
    }, [handleClick]);

    useEffect(() => {
        if (widthLoginButton !== document.querySelector(".login.primary").offsetWidth) {
            setWidthLoginButton(document.querySelector(".login.primary").offsetWidth);
        }
    });

    const handleLogout = () => {
        localStorage.removeItem("userToken");
        logout();
        navigate("/");
        navigate(0);
    };

    const sendCode = () => {
        setIsLoadingLogReg(true);
        UserApi.sendCode(phone).then(() => {
            setRegStatus("sendCode");
            setRecoveryStatus("sendCode");
            setError("");
            setIsLoadingLogReg(false);
        }).catch((e) => {
            setIsLoadingLogReg(false);
            setError(e.response.data.error_message)
        });
    };

    const changePassword = () => {
        setIsLoadingLogReg(true);
        UserApi.confirmPhone(phone, code).then((d) => {
            setError("");
            setIsLoadingLogReg(false);
            if (d.data.data.pass_token) {
                UserApi.setPassword(phone, password, d.data.data.pass_token, lastName, name, secondName, email, sex, birthday).then((d) => {
                    if (d.data.result) {
                        setRegStatus("ok");
                        setRecoveryStatus("ok");
                        document.location.reload();
                    }
                    setError("");
                }).catch((e) => {
                    setError(e.response.data.error_message)
                });
            }
        }).catch((e) => {
            setIsLoadingLogReg(false);
            setError(e.response.data.error_message)
        });
    };

    const CMSAPI = new CMS();

    const loginUser = () => {
        setIsLoading(true);
        UserApi.authClient(phone, password).then((d) => {
            UserApi.getAuthUserData(d.data.data.user_token).then((user) => {
                CMSAPI.queryContentByFilter("clients", {phone: user.data.data.phone.replace("+", "\+")}).then((clients) => {
                    console.log(clients.data);
                    if (clients.data.length === 0) {
                        console.log("client not found");
                        CMSAPI.addItem("clients", {
                            phone: user.data.data.phone.replace("+", "\+"),
                            email: user.data.data.email,
                            name: user.data.data.name + " " + user.data.data.second_name,
                        }).then(() => {
                            localStorage.setItem("userToken", d.data.data.user_token);
                            window.location.reload();
                            setError("");
                            setIsLoadingLogReg(false);
                        });
                    } else {
                        localStorage.setItem("userToken", d.data.data.user_token);
                        window.location.reload();
                        setError("");
                        setIsLoadingLogReg(false);
                    }
                });

            });
        }).catch((e) => {
            setIsLoadingLogReg(false);
            setError(e.response.data.error_message)
        });
    };

    return <div className={`primaryMenu ${isOpen ? "open" : null}`}>
        <button className={`primary login isOnline ${isOpen ? "open" : null}`}>
            <img className="fingerprintImage" src={FingerprintImage} alt=""/>
            <p>ЛИЧНЫЙ КАБИНЕТ</p>
        </button>
        <div className="menu" style={{width: widthLoginButton + 1}}>
            {
                userToken
                    ? <>
                        <p className="name">{userData.last_name} {userData.name} {userData.second_name}</p>
                        <Link to="/lk" className="link">Личный кабинет</Link>
                        <Link to="/support" className="link">Тех. Поддержка</Link>
                        <Link to="/lk/settings" className="link">Настройки</Link>
                        <button className="outlined fullWidth" onClick={() => handleLogout()}>
                            Выход
                        </button>
                    </>
                    : <>
                        {
                            frame === "login"
                                ? <>
                                    <IMaskInput mask={'+{7}(000) 000-00-00'} className="outlined"
                                                placeholder="Номер телефона"
                                                onChange={(e) => setPhone(e.target.value.replace("+", "").replace("(", "").replace(")", "").replaceAll("-", "").replaceAll(" ", ""))}/>
                                    <input type="password" className="outlined" placeholder="Пароль"
                                           onChange={(e) => setPassword(e.target.value)}/>

                                    {
                                        error !== ""
                                            ? <p className="error">ОШИБКА! {error}</p>
                                            : null
                                    }
                                    {
                                        !isLoadingLogReg
                                            ?
                                            <button className="outlined fullWidth" onClick={() => loginUser()}>Вход</button>
                                            : <button className="outlined fullWidth">Загрузка...</button>
                                    }
                                    <div className="links">
                                        <a href="#" onClick={() => setFrame("reg")}
                                           className="link">Регистрация</a><span>/</span><a href="#"
                                                                                            onClick={() => setFrame("recovery")}
                                                                                            className="link">Забыли
                                        пароль?</a>
                                    </div>
                                </>
                                : null
                        }
                        {
                            frame === "reg"
                                ? <>
                                    {
                                        regStatus === "inputPhone"
                                            ? <>
                                                <IMaskInput mask={'+{7}(000) 000-00-00'} autoComplete="field1"
                                                            className="outlined"
                                                            id="field1" placeholder="Номер телефона"
                                                            onChange={(e) => setPhone(e.target.value.replace("+", "").replace("(", "").replace(")", "").replaceAll("-", "").replaceAll(" ", ""))}/>
                                                <p>Код прийдет на WhatsApp</p>
                                                <br/>
                                            </>
                                            : null
                                    }
                                    {
                                        regStatus === "sendCode"
                                            ? <>
                                                <input autoComplete="off" id="code" placeholder="Код из WhatsApp"
                                                       className="outlined"
                                                       onChange={(e) => setCode(e.target.value)} type="text"/>
                                                <input autoComplete="off" placeholder="Новый пароль" className="outlined"
                                                       onChange={(e) => setPassword(e.target.value)}
                                                       type="password"/>
                                                <input placeholder="Имя" className="outlined"
                                                       onChange={(e) => setLastName(e.target.value)}
                                                       type="text"/>
                                                <input placeholder="Фамилия" className="outlined"
                                                       onChange={(e) => setName(e.target.value)} type="text"/>
                                                <input placeholder="Отчество" className="outlined"
                                                       onChange={(e) => setSecondName(e.target.value)}
                                                       type="text"/>
                                                <input placeholder="Email" className="outlined"
                                                       onChange={(e) => setEmail(e.target.value)}
                                                       type="text"/>
                                                <label htmlFor="start">День рождения</label>
                                                <input className="outlined"
                                                       onChange={(e) => setBirthday(e.target.value)} type="date" id="start"
                                                       name="trip-start"
                                                       defaultValue={moment(Date.now()).format("YYYY-MM-DD")}
                                                       min="1900-01-01" max="2023-01-01"/>
                                                <p>Пол:</p>
                                                <div className="checkboxGroup">
                                                    <div className="checkbox">
                                                        <input type="checkbox" value="1" onChange={() => setSex(1)}
                                                               checked={sex === 1}/><label
                                                        htmlFor="">М</label>
                                                    </div>
                                                    <div className="checkbox">
                                                        <input type="checkbox" value="2" onChange={() => setSex(2)}
                                                               checked={sex === 2}/><label
                                                        htmlFor="">Ж</label>
                                                    </div>
                                                </div>
                                            </>
                                            : null
                                    }
                                    {
                                        regStatus === "ok"
                                            ? <p>ОК! Ваш аккаунт создан</p>
                                            : null
                                    }
                                    {
                                        error !== ""
                                            ? <p className="error">ОШИБКА! {error}</p>
                                            : null
                                    }
                                    {
                                        !isLoadingLogReg
                                            ? <button className="outlined fullWidth"
                                                      onClick={regStatus === "inputPhone" ? () => sendCode() : () => changePassword()}>{regStatus === "inputPhone" ? "Отправить код" : "Регистрация"}</button>
                                            : <button className="outlined fullWidth">Загрузка...</button>
                                    }
                                    <div className="links">
                                        <a href="#" onClick={() => setFrame("login")}
                                           className="link">Вход</a><span>/</span><a
                                        href="#" onClick={() => setFrame("recovery")} className="link">Забыли пароль?</a>
                                    </div>
                                </>
                                : null
                        }
                        {
                            frame === "recovery"
                                ? <>
                                    {
                                        recoveryStatus === "inputPhone"
                                            ? <>
                                                <IMaskInput mask={'+{7}(000) 000-00-00'} autoComplete="field1"
                                                            className="outlined"
                                                            id="field1" placeholder="Номер телефона"
                                                            onChange={(e) => setPhone(e.target.value.replace("+", "").replace("(", "").replace(")", "").replaceAll("-", "").replaceAll(" ", ""))}/>
                                                <p>Код прийдет на WhatsApp</p>
                                                <br/>
                                            </>
                                            : null
                                    }
                                    {
                                        recoveryStatus === "sendCode"
                                            ? <>
                                                <input autoComplete="off" id="code" placeholder="Код из WhatsApp"
                                                       className="outlined"
                                                       onChange={(e) => setCode(e.target.value)} type="text"/>
                                                <input autoComplete="off" placeholder="Новый пароль" className="outlined"
                                                       onChange={(e) => setPassword(e.target.value)}
                                                       type="password"/>
                                            </>
                                            : null
                                    }
                                    {
                                        recoveryStatus === "ok"
                                            ? <p>ОК! Ваш аккаунт создан</p>
                                            : null
                                    }
                                    {
                                        error !== ""
                                            ? <p className="error">ОШИБКА! {error}</p>
                                            : null
                                    }
                                    {
                                        !isLoadingLogReg
                                            ? <button className="outlined fullWidth"
                                                      onClick={regStatus === "inputPhone" ? () => sendCode() : () => changePassword()}>{regStatus === "inputPhone" ? "Отправить код" : "Сменить пароль"}</button>
                                            : <button className="outlined fullWidth">Загрузка...</button>
                                    }
                                    <div className="links">
                                        <a href="#" onClick={() => setFrame("login")}
                                           className="link">Вход</a><span>/</span><a
                                        href="#" onClick={() => setFrame("reg")} className="link">Регистрация</a>
                                    </div>
                                </>
                                : null
                        }
                    </>
            }
        </div>
    </div>
};

const HeaderComponent = (props) => {
    const langs = [
        {label: "EN", value: "en"},
        {label: "RU", value: "ru"},
    ];
    const navigate = useNavigate();
    const clubStatic = new ClubStatic();
    const isAuth = useSelector(state => state.auth.isAuth);

    const [clubWorkload, setClubWorkload] = useState(0);
    const [isModalAuthOpen, setIsModalAuthOpen] = useState(false);

    useEffect(() => {
        let clubWorkloadTimeout = setTimeout(() => {
            clubStatic.getClubWorkload("a79874c9-91a4-11e9-9a3e-6c2b598ba92b").then((d) => setClubWorkload(d.data.data.count));
        }, 200);
        let clubWorkloadInterval = setInterval(() => {
            clubStatic.getClubWorkload("a79874c9-91a4-11e9-9a3e-6c2b598ba92b").then((d) => setClubWorkload(d.data.data.count));
        }, 60000);

        return () => {
            clearInterval(clubWorkloadInterval);
            clearTimeout(clubWorkloadTimeout);
        };
    });

    const [widthPage, setWidthPage] = useState(window.outerWidth);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    useEffect(() => {
        if (widthPage !== (window.outerWidth)) {
            if (window.outerWidth <= 600) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
            setWidthPage(window.outerWidth);
        }
    });

    return <>
        <div className={`headerComponent`} style={isMobile ? {width: widthPage} : {}}>
            <div className="firstContainer">
                <img className="logo" src={LogoImage} alt="logoImage" onClick={() => navigate("/")}/>
                <Select options={langs}/>
                <div className={`online ${clubWorkload === 0 ? "off" : null}`}>
                    <OnlineSvgImage/>
                    <div className="text">
                        <p className="count">{clubWorkload} человек</p>
                        <p className="info">сейчас в клубе</p>
                    </div>
                </div>
            </div>
            <div className="social">
                <a className="whatsAppSvg" href={props.mainInfo?.whatsapp}><WhatsAppImage/></a>
                <a className="telegramSvg" href={props.mainInfo?.Telegram}><TelegramImage/></a>
                <a className="vkImage" href={props.mainInfo?.vk}><VKImage/></a>
            </div>
            <HiddenPhoneNumber number="74951203639" blue/>
            <div className="addAmountBalance" onClick={() => isAuth ? navigate("/fortuna") : setIsModalAuthOpen(true)}>
                <p className="text">Колесо фортуны испытай удачу</p>
                <div className="icon">
                    <img src={BalanceImage} alt=""/>
                </div>
            </div>
            <div className="secondContainer">
                <SvgMenu/>
                <LKButtonMenu/>
            </div>
        </div>
        <div className="modal" style={{display: isModalAuthOpen ? "flex" : "none"}}
             onClick={e => (e.currentTarget === e.target) && setIsModalAuthOpen(false)}
        >
            <div className="content">
                <p className="title">Необходима авторизация</p>
                <p>Для участия в фортуне необходима авторизация</p>
                <br/>
                <button className="close" onClick={() => {navigate("/loginReg"); setIsModalAuthOpen(false);}}>Вход\Регистрация</button>
                <button className="close" onClick={() => setIsModalAuthOpen(false)}>Закрыть</button>
            </div>
        </div>
    </>
};

export default HeaderComponent;