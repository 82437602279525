import React, {useEffect, useState} from "react";
import CMS from "../Api/CMS";
import DocumentMeta from "react-document-meta";
import {useSelector} from "react-redux";

const InteractiveInfoStepsComponent = (props) => {
    const CMSAPI = new CMS();

    return <div className="items">
        {
            props.steps ? props.steps.map((e, i) => {
                return <div className="item">
                    <div className="borderImg">
                        <div className="img">
                            <img src={CMSAPI.getImageLink(e.image.path)} alt=""/>
                        </div>
                    </div>
                    <p className="title">{e.title}</p>
                    <p className="desc" dangerouslySetInnerHTML={{__html: e.text}}/>
                </div>
            }) : null
        }
    </div>
};

const ForNovicePage = () => {
    const infoForNovice = useSelector(state => state.content.interactiveInfo.forNewbies);
    const steps = useSelector(state => state.content.interactiveInfo.steps);
    const seo = useSelector(state => state.content.seoArray.find(seoItem => seoItem.pageTag === "forNovice"));

    return <DocumentMeta {...{
        title: seo.title,
        description: seo.description,
        meta: {
            charset: "utf-8",
            name: {
                keywords: seo.tags,
            },
        },
    }}>
        <div className="inPage">
            <div className="block">
                <p className="title">{infoForNovice.titleInText}</p>
                <p className="text" dangerouslySetInnerHTML={{__html: infoForNovice.text}}/>
                <br/>
                <InteractiveInfoStepsComponent steps={steps}/>
            </div>
        </div>
    </DocumentMeta>
};

export default ForNovicePage;