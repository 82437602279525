import axios from "axios";
import CMS from "./CMS";

export const sendMail = async (subject, altbody, body) => {
    const CMSAPI = new CMS();

    CMSAPI.queryContentAll("notifyEmails").then(mails => {
        const mailList = mails.data;

        mailList.map(m => {
            axios.post("https://спорт-макс.рф/sendMail.php", {
                emailTo: m.email,
                emailToName: m.name,
                subject: subject,
                altbody: altbody,
                body: body,
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            });
        });
    });
};