import Query from "./Query";

class GroupTimes {
    constructor() {
        this.api = new Query();
    }

    getGroupTimes(startDate, endDate, clubId) {
        return this.api.query("/classes", {
            start_date: Query.convertDateTo1C(startDate),
            end_date: Query.convertDateTo1C(endDate),
            club_id: clubId,
        });
    }
}

export default GroupTimes;