import axios from "axios";

class CMS {
    constructor(token = "API-ab26097fc31afffe54f2bd30e742393b22880bed", host = "https://спорт-макс.рф/cms", url = "/api", urlContent = "/storage/uploads") {
        this.host = host;
        this.token = token;
        this.url = this.host + url;
        this.urlContent = this.host + urlContent;
    }

    queryContentAll(collection) {
        return axios.get(this.url + "/content/items/" + collection + "?v=1", {
            headers: {
                "api-key": this.token,
            },
        });
    }

    queryContentFirst(collection) {
        return axios.get(this.url + "/content/item/" + collection + "?v=1", {
            headers: {
                "api-key": this.token,
            },
        });
    }

    queryContentById(collection, id) {
        return axios.get(this.url + "/content/item/" + collection + "/" + id + "?v=1", {
            headers: {
                "api-key": this.token,
            },
        });
    }

    queryContentByFilter(collection, filter) {
        return axios.get(this.url + "/content/items/" + collection + "?filter=" + encodeURIComponent(JSON.stringify(filter)) + "&v=1", {
            headers: {
                "api-key": this.token,
            },
        });
    }


    addItem(collection, data, callback = () => {}) {
        return axios.post(this.url + "/content/item/" + collection + "?v=1", {
            data: data,
        }, {
            headers: {
                "api-key": this.token,
            },
        }).then(() => callback());
    }

    getImageLink(path) {
        return this.urlContent + path + "?v=1";
    }

    async getCurrentEvent() {
        const contacts = await this.queryContentFirst("contacts");
        return this.queryContentById("events", contacts.data.activeEvent?._id);
    }
}

export default CMS;