import React, {useEffect, useState} from "react";

const HiddenPhoneNumber = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => setIsOpen(false), 10000);
        }
    });

    return <a href={`tel:+${props.number}`} className={`phoneNumber ${isOpen ? "open" : null}`} onClick={() => setIsOpen(!isOpen)}>
        <span className="code">+{props.number.substring(0, 1)}</span>
        <span className={`operator ${props.blue ? "blue" : null}`}>{props.number.substring(1, 4)}</span>
        <span className="main">{props.number.substring(4, 7)}-{props.number.substring(7, 9)}-{props.number.substring(9, 11)}</span>
    </a>
};

export default HiddenPhoneNumber;