import React, {useEffect, useMemo, useState} from "react";
import CMS from "../Api/CMS";
import AboutBlock from "../Components/PageParts/AboutBlock/AboutBlock";
import DocumentMeta from "react-document-meta";

const AboutPage = () => {
    const CMSAPI = useMemo(() => new CMS(), []);

    const [seo, setSeo] = useState({
        title: "",
        description: "",
        tags: "",
    });

    const [heightPage, setHeightPage] = useState(window.innerHeight - 100);
    const [mainInfo, setMainInfo] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (heightPage !== (window.innerHeight - 100)) {
            setHeightPage(window.innerHeight - 100);
        }
        CMSAPI.queryContentByFilter("seo", {pageTag: "about"}).then((data) => {
            setSeo(data.data[0]);
        });
        if (isLoading) {
            setIsLoading(false);
            CMSAPI.queryContentFirst("contacts").then((data) => {
                setMainInfo(data.data);
            });
        }
    }, [isLoading, heightPage, CMSAPI]);

    return <DocumentMeta {...{
        title: seo.title,
        description: seo.description,
        meta: {
            charset: "utf-8",
            name: {
                keywords: seo.tags,
            },
        },
    }}>
        <div className="inPage">
            <AboutBlock heightPage={heightPage} mainInfo={mainInfo}/>
        </div>
    </DocumentMeta>
};

export default AboutPage;