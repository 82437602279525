import React, {useEffect, useState} from "react";
import moment from "moment";
import "moment/locale/ru";
import GroupTimes from "../../Api/GroupTimes";
import PuffLoader from "react-spinners/PuffLoader";
import User from "../../Api/User";
import {useNavigate} from "react-router-dom";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CMS from "../../Api/CMS";
import DocumentMeta from "react-document-meta";

const GroupTimesPageInOneDay = () => {
    const navigate = useNavigate();
    const [originalDate] = useState(moment(Date.now()).locale("ru"));
    const [currentDate, setCurrentDate] = useState(moment(Date.now()).locale("ru"));
    const [isLoading, setIsLoading] = useState(true);
    const [rawData, setRawData] = useState([{}]);
    const [data, setData] = useState([{}]);
    const [filterTime] = useState("all");
    const [filterTitle, setFilterTitle] = useState("");
    const [titles, setTitles] = useState([""]);
    const [filterEmployee, setFilterEmployee] = useState("");
    const [employees, setEmployees] = useState([""]);
    const [filterRoom, setFilterRoom] = useState("");
    const [rooms, setRooms] = useState([""]);
    const [filterCategory, setFilterCategory] = useState("");
    const [categories, setCategories] = useState([""]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({
        service: {
            title: "",
        },
        employee: {
            name: "",
            position: {
                title: "",
            },
        }
    });
    const dates = ["", "", "", "", "", "", "", "", "", "", "", "", "", ""];
    const query = new GroupTimes();
    const [userToken] = useState(localStorage.getItem("userToken") ? localStorage.getItem("userToken") : "");
    const UserApi = new User();
    const [isModalLoginOpen, setIsModalLoginOpen] = useState(false);
    const bookingNotify = () => toast("Вы забронировали место в групповом занятии");
    const CMSAPI = new CMS();

    const getGroups = (date = currentDate.format("YYYY-MM-DD")) => {
        query.getGroupTimes(moment(date).add(0, "days"), moment(date).add(1, "days"), "a79874c9-91a4-11e9-9a3e-6c2b598ba92b").then(d => {
            setRawData(d.data.data);
            setData(searchTimes(d.data.data));
        });
    };

    const searchTimes = (data) => {
        let arr = [];
        let min = 0;
        let max = 24;
        if (filterTime === "all") {
            min = 0;
            max = 24;
        } else if (filterTime === "morning") {
            min = 8;
            max = 13;
        } else if (filterTime === "day") {
            min = 13;
            max = 18;
        } else if (filterTime === "evening") {
            min = 18;
            max = 24;
        }
        for (let i = min; i < max; i++) {
            const str = moment().hour(i).minute(0).format("HH:");
            let employee = [];
            let groups = [];
            let title = [];
            let room = [];
            let category = [];
            data.map(d => {if(!title.includes(d.service.title)){title.push(d.service.title)}});
            data.map(d => {if(!employee.includes(d.employee.name)){employee.push(d.employee.name)}});
            data.map(d => {if(!room.includes(d.room.title)){room.push(d.room.title)}});
            data.map(d => {if(!category.includes(d.group.title)){category.push(d.group.title)}});
            let fRoom = "";
            if (window.innerWidth <= 900) {
                filterRoom === "" ? fRoom = room[0] : fRoom = filterRoom;
            } else {
                fRoom = "";
            }
            data.find(g => {let groupTitle = ""; if (g.group.title) {groupTitle = g.group.title} if (g.start_date.includes(str) && g.service.title.includes(filterTitle) && g.employee.name.includes(filterEmployee) && g.room.title.includes(fRoom) && groupTitle.includes(filterCategory)) {groups.push(g);}});
            arr.push({
                time: str + "00",
                groups: groups,
            });
            setTitles(title);
            setEmployees(employee);
            setCategories(category);
            setRooms(room);
        }
        setIsLoading(false);
        return arr;
    };

    const searchRooms = (room, d) => {
        let arr = [];
        d.find(group => group.room.title.includes(room) ? arr.push(group) : null);
        if (window.innerWidth <= 900) {
            return arr.map(group =>
                group ? <td>
                    <div className="card" onClick={() => openModal(group)}>
                        <p className="time">{moment(group.start_date).format("HH:mm")}-{moment(group.end_date).format("HH:mm")}</p>
                        <p className="duration">{group.duration} мин.</p>
                        <p className="title">{group.service.title}</p>
                        <p className="employee">{group.employee.name}</p>
                        <p className="room">{group.room.title}</p>
                        {
                            group.available_slots !== 0 && group.available_slots !== "unlimited"
                                ?
                                <p className="available_slots">Свободно: <b>{group.available_slots}</b> из <b>{group.capacity}</b>
                                </p>
                                : null
                        }
                        {
                            group.available_slots === "unlimited"
                                ? <p className="available_slots"><b>Без ограничений по местам</b></p>
                                : null
                        }
                    </div>
                </td> : null
            )
        } else {
            return <td>{arr.map(group =>
                group ?
                    <div className="card" onClick={() => openModal(group)}>
                        <p className="time">{moment(group.start_date).format("HH:mm")}-{moment(group.end_date).format("HH:mm")}</p>
                        <p className="duration">{group.duration} мин.</p>
                        <p className="title">{group.service.title}</p>
                        <p className="employee">{group.employee.name}</p>
                        <p className="room">{group.room.title}</p>
                        {
                            group.available_slots !== 0 && group.available_slots !== "unlimited"
                                ?
                                <p className="available_slots">Свободно: <b>{group.available_slots}</b> из <b>{group.capacity}</b>
                                </p>
                                : null
                        }
                        {
                            group.available_slots === "unlimited"
                                ? <p className="available_slots"><b>Без ограничений по местам</b></p>
                                : null
                        }
                    </div> : null
            )}</td>
        }
    };

    const booking = (id) => {
        if (userToken === "") {
            setIsModalLoginOpen(true);
        } else {
            UserApi.bookingAuthUser(userToken, id).then(d => setIsModalOpen(false));
            bookingNotify();
        }
    };

    const [seo, setSeo] = useState({
        title: "",
        description: "",
        tags: "",
    });

    useEffect(() => {
        if (isLoading) {
            getGroups();
        }
        CMSAPI.queryContentByFilter("seo", {pageTag: "groupTimesInOneDay"}).then((data) => {
            setSeo(data.data[0]);
        });
    });

    const handleChangeDate = async (event) => {
        const newDate = moment(event.target.value).locale("ru");
        await setCurrentDate(newDate);
        getGroups(newDate);
    };

    const handleChangeFilterTitle = async (event) => {
        setIsLoading(true);
        await setFilterTitle(event.target.value);
        setData(searchTimes(rawData));
    };

    const handleChangeFilterEmployee = async (event) => {
        setIsLoading(true);
        await setFilterEmployee(event.target.value);
        setData(searchTimes(rawData));
    };

    const handleChangeFilterCategory = async (event) => {
        setIsLoading(true);
        await setFilterCategory(event.target.value);
        setData(searchTimes(rawData));
    };

    const handleChangeFilterRoom = async (room) => {
        setIsLoading(true);
        await setFilterRoom(room);
        setData(searchTimes(rawData));
    };

    const openModal = (data) => {
        setModalData(data);
        setIsModalOpen(true);
    };

    return <DocumentMeta {...{
        title: seo.title,
        description: seo.description,
        meta: {
            charset: "utf-8",
            name: {
                keywords: seo.tags,
            },
        },
    }}>
        <div className="page">
            <div className="filter inOneDay">
                <select onChange={handleChangeDate}>
                    <option value={moment(originalDate).add(0, "day").format("YYYY-MM-DD")} selected disabled>Дата
                    </option>
                    <option value={moment(originalDate).add(0, "day").format("YYYY-MM-DD")}>Сегодня</option>
                    {
                        dates.map((j, i) => <option
                            value={moment(originalDate).add(i + 1, "day").format("YYYY-MM-DD")}>{moment(originalDate).locale("ru").add(i + 1, "day").format("D MMMM, dd")}</option>)
                    }
                </select>
                <select onChange={handleChangeFilterTitle}>
                    <option value="" selected disabled>Название занятия</option>
                    <option value="">Все занятия</option>
                    {
                        titles.map(title => {
                            return <option value={title}>{title}</option>
                        })
                    }
                </select>
                <select onChange={handleChangeFilterEmployee}>
                    <option value="" selected disabled>Преподаватель</option>
                    <option value="">Все преподаватели</option>
                    {
                        employees.map(employee => {
                            return <option value={employee}>{employee}</option>
                        })
                    }
                </select>
                <select onChange={handleChangeFilterCategory}>
                    <option value="" selected disabled>Группа</option>
                    <option value="">Все группы</option>
                    {
                        categories.map(category => {
                            return <option value={category}>{category}</option>
                        })
                    }
                </select>
            </div>
            <table className="timeTable">
                <tr className="header">
                    <thead className="button">
                    <button className="manipulateDate" onClick={() => handleChangeDate(-7)}>{"<"}</button>
                    </thead>
                    {
                        rooms.map(room => <thead>{room}</thead>)
                    }
                    <thead className="button">
                    <button className="manipulateDate" onClick={() => handleChangeDate(7)}>{">"}</button>
                    </thead>
                </tr>
                <div className="tableSpace"/>
                {
                    isLoading
                        ? <div className="loader">
                            <PuffLoader
                                color={"#33ccff"}
                                size={50}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                        : data.map(time =>
                            time.groups[0] !== undefined
                                ? <tr className="row">
                                    <td className="time"><p>{time.time}</p></td>
                                    {
                                        rooms.map(room => searchRooms(room, time.groups))
                                    }
                                    <td className="time"><p>{time.time}</p></td>
                                </tr> : null
                        )
                }
            </table>
            <table className="timeTable medium">
                <tr className="header">
                    {
                        rooms.map(room => <thead>
                        <button onClick={() => handleChangeFilterRoom(room)}>{room === filterRoom ?
                            <b>{room}</b> : `${room}`}</button>
                        </thead>)
                    }
                </tr>
                <div className="tableSpace"/>
                {
                    isLoading
                        ? <div className="loader">
                            <PuffLoader
                                color={"#33ccff"}
                                size={50}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                        : data.map(time =>
                            time.groups[0] !== undefined
                                ? <tr className="row">
                                    <td className="time"><p>{time.time}</p></td>
                                    {
                                        rooms.map(room => searchRooms(room, time.groups))
                                    }
                                    <td className="time"><p>{time.time}</p></td>
                                </tr> : null
                        )
                }
            </table>
            <table className="timeTable small">
                <tr className="header">
                    {
                        rooms.map(room => <thead>
                        <button onClick={() => handleChangeFilterRoom(room)}>{room === filterRoom ?
                            <b>{room}</b> : `${room}`}</button>
                        </thead>)
                    }
                </tr>
                <div className="tableSpace"/>
                {
                    isLoading
                        ? <div className="loader">
                            <PuffLoader
                                color={"#33ccff"}
                                size={50}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                        : data.map(time =>
                            time.groups[0] !== undefined
                                ? <tr className="row">
                                    <td className="time"><p>{time.time}</p></td>
                                    {
                                        rooms.map(room => searchRooms(room, time.groups))
                                    }
                                    <td className="time"><p>{time.time}</p></td>
                                </tr> : null
                            )
                }
            </table>
            <div className="modal" style={{display: isModalOpen ? "flex" : "none"}}
                 onClick={e => (e.currentTarget === e.target) && setIsModalOpen(false)}>
                <div className="content">
                    <p className="title">{modalData.service.title}</p>
                    <p>{modalData.service.description}</p>
                    <br/>
                    <p className="time">
                        {moment(modalData.start_date).format("dd DD.MM.YYYY")} {moment(modalData.start_date).format("HH:mm")} - {moment(modalData.end_date).format("HH:mm")}
                        <p className="duration">{modalData.duration} мин.</p>
                    </p>
                    <hr/>
                    <div className="teacher">
                        <p className="employee">{modalData.employee.name}</p>
                        <p className="employeeDesc">{modalData.employee.position.title}</p>
                    </div>
                    <hr/>
                    {
                        modalData.available_slots !== 0 && modalData.available_slots !== "unlimited"
                            ?
                            <p className="available_slots">Свободно: <b>{modalData.available_slots}</b> из <b>{modalData.capacity}</b>
                            </p>
                            : null
                    }
                    {
                        modalData.available_slots === "unlimited"
                            ? <p className="available_slots"><b>Без ограничений по местам</b></p>
                            : null
                    }
                    {
                        modalData.available_slots !== 0
                            ? <button className="booking"
                                      onClick={() => booking(modalData.appointment_id)}>Забронировать</button>
                            : null
                    }
                    <button className="close" onClick={() => setIsModalOpen(false)}>Закрыть</button>
                </div>
            </div>
            <div className="modal" style={{display: isModalLoginOpen ? "flex" : "none"}}
                 onClick={e => (e.currentTarget === e.target) && setIsModalLoginOpen(false)}>
                <div className="content">
                    <p className="title">Для бронирования, пожалуйста, войдите</p>
                    <button className="booking" onClick={() => navigate("/lk")}>Вход\Регистрация</button>
                    <button className="close" onClick={() => setIsModalLoginOpen(false)}>Закрыть</button>
                </div>
            </div>
            <ToastContainer/>
        </div>
    </DocumentMeta>
};

export default GroupTimesPageInOneDay;