import React, {useEffect, useState} from "react";
import moment from "moment";
import "moment/locale/ru";
import GroupTimes from "../../Api/GroupTimes";
import User from "../../Api/User";
import PuffLoader from "react-spinners/PuffLoader";
import {useNavigate} from "react-router-dom";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CMS from "../../Api/CMS";
import DocumentMeta from "react-document-meta";
import {useDispatch, useSelector} from "react-redux";
import {refresh} from "../../Store/authSlice";
import {sendMail} from "../../Api/SMTP";

const GroupTimesPage = () => {
    const navigate = useNavigate();
    const [currentDate, setCurrentDate] = useState(moment(Date.now()).locale("ru"));
    const [renderCurrentDate, setRenderCurrentDate] = useState(moment(Date.now()).locale("ru"));
    const [isLoading, setIsLoading] = useState(true);
    const [rawData, setRawData] = useState([{}]);
    const [data, setData] = useState([{}]);
    const [filterTime, setFilterTime] = useState("all");
    const [filterTitle, setFilterTitle] = useState("");
    const [titles, setTitles] = useState([""]);
    const [filterEmployee, setFilterEmployee] = useState("");
    const [employees, setEmployees] = useState([""]);
    const [filterRoom, setFilterRoom] = useState("");
    const [rooms, setRooms] = useState([""]);
    const [filterCategory, setFilterCategory] = useState("");
    const [categories, setCategories] = useState([""]);
    const [isModalLoginOpen, setIsModalLoginOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({
        service: {
            title: "",
        },
        employee: {
            name: "",
            position: {
                title: "",
            },
        }
    });
    const [userToken] = useState(localStorage.getItem("userToken") ? localStorage.getItem("userToken") : "");
    const UserApi = new User();
    const bookingNotify = () => toast("Вы забронировали место в групповом занятии");
    const CMSAPI = new CMS();
    const dispatch = useDispatch();
    const {userData, userBookings} = useSelector(state => state.auth);

    const seo = useSelector(state => state.content.seoArray.find(seoItem => seoItem.pageTag === "groupTimes"));

    const getGroups = () => {
        const query = new GroupTimes();
        if (window.innerWidth <= 750) {
            query.getGroupTimes(moment(currentDate).add(0, "days").hour(0), moment(currentDate).add(1, "days").hour(0), "a79874c9-91a4-11e9-9a3e-6c2b598ba92b").then(d => {
                setRawData(d.data.data);
                setData(searchTimes(d.data.data));
            });
        } else if (window.innerWidth <= 900) {
            query.getGroupTimes(moment(currentDate).add(-1, "days").hour(0), moment(currentDate).add(2, "days").hour(0), "a79874c9-91a4-11e9-9a3e-6c2b598ba92b").then(d => {
                setRawData(d.data.data);
                setData(searchTimes(d.data.data));
            });
        } else {
            query.getGroupTimes(moment(currentDate).add(-3, "days").hour(0), moment(currentDate).add(4, "days").hour(0), "a79874c9-91a4-11e9-9a3e-6c2b598ba92b").then(d => {
                setRawData(d.data.data);
                setData(searchTimes(d.data.data));
            });
        }
    };

    const searchTimes = (data) => {
        let arr = [];
        let min = 0;
        let max = 24;
        if (filterTime === "all") {
            min = 0;
            max = 24;
        } else if (filterTime === "morning") {
            min = 8;
            max = 13;
        } else if (filterTime === "day") {
            min = 13;
            max = 18;
        } else if (filterTime === "evening") {
            min = 18;
            max = 24;
        }
        for (let i = min; i < max; i++) {
            const str = moment().hour(i).minute(0).format("HH:");
            let employee = [];
            let groups = [];
            let title = [];
            let room = [];
            let category = [];
            data.map(d => {
                if (!title.includes(d.service.title)) {
                    title.push(d.service.title)
                }
            });
            data.map(d => {
                if (!employee.includes(d.employee.name)) {
                    employee.push(d.employee.name)
                }
            });
            data.map(d => {
                if (!room.includes(d.room.title)) {
                    room.push(d.room.title)
                }
            });
            data.map(d => {
                if (!category.includes(d.group.title)) {
                    category.push(d.group.title)
                }
            });
            data.find(g => {
                let groupTitle = "";
                if (g.group.title) {
                    groupTitle = g.group.title
                }
                if (g.start_date.includes(str) && g.service.title.includes(filterTitle) && g.employee.name.includes(filterEmployee) && g.room.title.includes(filterRoom) && groupTitle.includes(filterCategory)) {
                    groups.push(g);
                }
            });
            arr.push({
                time: str + "00",
                groups: groups,
            });
            setTitles(title);
            setEmployees(employee);
            setCategories(category);
            setRooms(room);
        }
        setIsLoading(false);
        return arr;
    };

    const searchDates = (operationCount, d) => {
        let startDate = moment(currentDate).add(operationCount, "days").format("YYYY-MM-DD");
        let arr = [];
        d.map(group => group.start_date.includes(startDate) ? arr.push(group) : null);
        return <td>
            {
                arr.map(group => {
                    return <div className="card" onClick={() => openModal(group)}>
                        <p className="time">{moment(group.start_date).format("HH:mm")}-{moment(group.end_date).format("HH:mm")}</p>
                        <p className="duration">{group.duration} мин.</p>
                        <p className="title">{group.service.title}</p>
                        <p className="employee">{group.employee.name}</p>
                        <p className="room">{group.room.title}</p>
                        {
                            group.available_slots !== 0 && group.available_slots !== "unlimited"
                                ?
                                <p className="available_slots">Свободно: <b>{group.available_slots}</b> из <b>{group.capacity}</b>
                                </p>
                                : null
                        }
                        {
                            group.available_slots === "unlimited"
                                ? <p className="available_slots"><b>Без ограничений по местам</b></p>
                                : null
                        }
                    </div>
                })
            }
        </td>
    };

    const displayTableDate = (date, operationCount = 0) => {
        const d = moment(date).add(operationCount, "days");
        return <div>
            {
                currentDate.format("DD-MM-YYYY") === d.format("DD-MM-YYYY")
                    ? <>
                        <p><b>{d.format("D")}</b></p>
                        <p><b>{d.format("dd")}</b></p>
                    </>
                    : <>
                        <p>{d.format("D")}</p>
                        <p>{d.format("dd")}</p>
                    </>
            }
        </div>
    };

    const isThisDate = (date) => {
        const d = moment(date);
        return currentDate.format("DD-MM-YYYY") === d.format("DD-MM-YYYY")
    };

    const openModal = (data) => {
        setModalData(data);
        setIsModalOpen(true);
    };

    const booking = (id) => {
        if (userToken === "") {
            setIsModalLoginOpen(true);
        } else {
            UserApi.bookingAuthUser(userToken, id).then(d => {
                setIsModalOpen(false);
                bookingNotify();
                sendMail("Групповая запись", `Новая запись на групповое занятие ${modalData.service.title}`,
                    `
                    Новая запись на групповое занятие ${modalData.service.title} <br/>
                    Имя клиента: ${userData.name + " " + userData.last_name} <br/>
                    Номер телефона: ${userData.phone}
                `).then(() => {dispatch(refresh()); navigate("/lk")});
            });
        }
    };

    useEffect(() => {
        if (isLoading) {
            getGroups();
        }
    });

    const handleChangeDate = (operationCount) => {
        const newDate = moment(currentDate).locale("ru").add(operationCount, "days");
        setCurrentDate(newDate);
        setIsLoading(true);
    };

    const handleChangeDateForce = (date) => {
        setCurrentDate(date.locale("ru"));
        setIsLoading(true);
    };

    const handleChangeRenderDate = (operationCount) => {
        const newDate = moment(renderCurrentDate).locale("ru").add(operationCount, "days");
        setRenderCurrentDate(newDate);
        setCurrentDate(moment(currentDate).locale("ru").add(operationCount, "days"));
        setIsLoading(true);
    };

    const handleChangeFilterTime = (event) => {
        setIsLoading(true);
        setFilterTime(event.target.value);
        setData(searchDates(rawData));
    };

    const handleChangeFilterTitle = (event) => {
        setIsLoading(true);
        setFilterTitle(event.target.value);
        setData(searchDates(rawData));
    };

    const handleChangeFilterEmployee = (event) => {
        setIsLoading(true);
        setFilterEmployee(event.target.value);
        setData(searchDates(rawData));
    };

    const handleChangeFilterCategory = (event) => {
        setIsLoading(true);
        setFilterCategory(event.target.value);
        setData(searchDates(rawData));
    };

    const handleChangeFilterRoom = (event) => {
        setIsLoading(true);
        setFilterRoom(event.target.value);
        setData(searchDates(rawData));
    };

    return <DocumentMeta {...{
        title: seo.title,
        description: seo.description,
        meta: {
            charset: "utf-8",
            name: {
                keywords: seo.tags,
            },
        },
    }}>
        <div className="page">
            {/*// <div className="filter">*/}
            {/*//     <select onChange={handleChangeFilterTime}>*/}
            {/*//         <option value="all" selected disabled>Время</option>*/}
            {/*//         <option value="all">Весь день</option>*/}
            {/*//         <option value="morning">Утро</option>*/}
            {/*        <option value="day">День</option>*/}
            {/*        <option value="evening">Вечер</option>*/}
            {/*    </select>*/}
            {/*//     <select onChange={handleChangeFilterTitle}>*/}
            {/*//         <option value="" selected disabled>Название занятия</option>*/}
            {/*        <option value="">Все занятия</option>*/}
            {/*        {*/}
            {/*            titles.map(title => {*/}
            {/*                return <option value={title}>{title}</option>*/}
            {/*            })*/}
            {/*        }*/}
            {/*    </select>*/}
            {/*//     <select onChange={handleChangeFilterEmployee}>*/}
            {/*//         <option value="" selected disabled>Преподаватель</option>*/}
            {/*//         <option value="">Все преподаватели</option>*/}
            {/*        {*/}
            {/*            employees.map(employee => {*/}
            {/*                return <option value={employee}>{employee}</option>*/}
            {/*            })*/}
            {/*        }*/}
            {/*    </select>*/}
            {/*//     <select onChange={handleChangeFilterCategory}>*/}
            {/*//         <option value="" selected disabled>Группа</option>*/}
            {/*//         <option value="">Все группы</option>*/}
            {/*        {*/}
            {/*            categories.map(category => {*/}
            {/*                return <option value={category}>{category}</option>*/}
            {/*            })*/}
            {/*        }*/}
            {/*    </select>*/}
            {/*     <select onChange={handleChangeFilterRoom}>*/}
            {/*         <option value="" selected disabled>Зал</option>*/}
            {/*        <option value="">Все залы</option>*/}
            {/*        {*/}
            {/*            rooms.map(room => {*/}
            {/*                return <option value={room}>{room}</option>*/}
            {/*            })*/}
            {/*        }*/}
            {/*    </select>*/}
            {/*</div>*/}
            <table className="timeTable">
                <tr className="header">
                    <thead className="button">
                    <button className="manipulateDate" onClick={() => handleChangeDate(-7)}>{"<"}</button>
                    </thead>
                    <thead>{displayTableDate(currentDate, -3)}</thead>
                    <thead>{displayTableDate(currentDate, -2)}</thead>
                    <thead>{displayTableDate(currentDate, -1)}</thead>
                    <thead>{displayTableDate(currentDate)}</thead>
                    <thead>{displayTableDate(currentDate, 1)}</thead>
                    <thead>{displayTableDate(currentDate, 2)}</thead>
                    <thead>{displayTableDate(currentDate, 3)}</thead>
                    <thead className="button">
                    <button className="manipulateDate" onClick={() => handleChangeDate(7)}>{">"}</button>
                    </thead>
                </tr>
                <div className="tableSpace"/>
                {
                    isLoading
                        ? <div className="loader">
                            <PuffLoader
                                color={"#33ccff"}
                                size={50}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                        : data.map(time =>
                            time.groups[0]
                                ? <tr className="row">
                                    <td className="time"><p>{time.time}</p></td>
                                    <td>{searchDates(-3, time.groups)}</td>
                                    <td>{searchDates(-2, time.groups)}</td>
                                    <td>{searchDates(-1, time.groups)}</td>
                                    <td>{searchDates(0, time.groups)}</td>
                                    <td>{searchDates(1, time.groups)}</td>
                                    <td>{searchDates(2, time.groups)}</td>
                                    <td>{searchDates(3, time.groups)}</td>
                                    <td className="time"><p>{time.time}</p></td>
                                </tr> : null
                        )
                }
            </table>
            <table className="timeTable medium">
                <tr className="header">
                    <thead className="button">
                    <button className="manipulateDate" onClick={() => handleChangeDate(-3)}>{"<"}</button>
                    </thead>
                    <thead>{displayTableDate(currentDate, -1)}</thead>
                    <thead>{displayTableDate(currentDate)}</thead>
                    <thead>{displayTableDate(currentDate, 1)}</thead>
                    <thead className="button">
                    <button className="manipulateDate" onClick={() => handleChangeDate(3)}>{">"}</button>
                    </thead>
                </tr>
                <div className="tableSpace"/>
                {
                    isLoading
                        ? <div className="loader">
                            <PuffLoader
                                color={"#33ccff"}
                                size={50}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                        : data.map(time =>
                            time.groups[0]
                                ? <tr className="row">
                                    <td className="time"><p>{time.time}</p></td>
                                    <td>{searchDates(-1, time.groups)}</td>
                                    <td>{searchDates(0, time.groups)}</td>
                                    <td>{searchDates(1, time.groups)}</td>
                                    <td className="time"><p>{time.time}</p></td>
                                </tr> : null
                        )
                }
            </table>
            <table className="timeTable small">
                <tr className="header">
                    <thead>
                    <button onClick={() => handleChangeRenderDate(-7)}>{"<"}</button>
                    </thead>
                    <thead>
                    <button
                        className={isThisDate(moment(renderCurrentDate).add(-3, "days").format("YYYY-MM-DD")) ? "active" : null}
                        onClick={() => handleChangeDateForce(moment(renderCurrentDate).add(-3, "day"))}>{displayTableDate(renderCurrentDate, -3)}</button>
                    </thead>
                    <thead>
                    <button
                        className={isThisDate(moment(renderCurrentDate).add(-2, "days").format("YYYY-MM-DD")) ? "active" : null}
                        onClick={() => handleChangeDateForce(moment(renderCurrentDate).add(-2, "day"))}>{displayTableDate(renderCurrentDate, -2)}</button>
                    </thead>
                    <thead>
                    <button
                        className={isThisDate(moment(renderCurrentDate).add(-1, "days").format("YYYY-MM-DD")) ? "active" : null}
                        onClick={() => handleChangeDateForce(moment(renderCurrentDate).add(-1, "day"))}>{displayTableDate(renderCurrentDate, -1)}</button>
                    </thead>
                    <thead>
                    <button
                        className={isThisDate(moment(renderCurrentDate).add(0, "days").format("YYYY-MM-DD")) ? "active" : null}
                        onClick={() => handleChangeDateForce(moment(renderCurrentDate).add(0, "day"))}>{displayTableDate(renderCurrentDate)}</button>
                    </thead>
                    <thead>
                    <button
                        className={isThisDate(moment(renderCurrentDate).add(1, "days").format("YYYY-MM-DD")) ? "active" : null}
                        onClick={() => handleChangeDateForce(moment(renderCurrentDate).add(1, "day"))}>{displayTableDate(renderCurrentDate, 1)}</button>
                    </thead>
                    <thead>
                    <button
                        className={isThisDate(moment(renderCurrentDate).add(2, "days").format("YYYY-MM-DD")) ? "active" : null}
                        onClick={() => handleChangeDateForce(moment(renderCurrentDate).add(2, "day"))}>{displayTableDate(renderCurrentDate, 2)}</button>
                    </thead>
                    <thead>
                    <button
                        className={isThisDate(moment(renderCurrentDate).add(3, "days").format("YYYY-MM-DD")) ? "active" : null}
                        onClick={() => handleChangeDateForce(moment(renderCurrentDate).add(3, "day"))}>{displayTableDate(renderCurrentDate, 3)}</button>
                    </thead>
                    <thead>
                    <button onClick={() => handleChangeRenderDate(7)}>{">"}</button>
                    </thead>
                </tr>
                <div className="tableSpace"/>
                {
                    isLoading
                        ? <div className="loader">
                            <PuffLoader
                                color={"#33ccff"}
                                size={50}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                        : data.map(time =>
                            time.groups[0]
                                ? <tr className="row">
                                    <td className="time"><p>{time.time}</p></td>
                                    <td>{searchDates(0, time.groups)}</td>
                                    <td className="time"><p>{time.time}</p></td>
                                </tr> : null
                        )
                }
            </table>
            <div className="modal" style={{display: isModalOpen ? "flex" : "none"}}
                 onClick={e => (e.currentTarget === e.target) && setIsModalOpen(false)}>
                <div className="content">
                    <p className="title">{modalData.service.title}</p>
                    <p>{modalData.service.description}</p>
                    <br/>
                    <p className="time">
                        {moment(modalData.start_date).format("dd DD.MM.YYYY")} {moment(modalData.start_date).format("HH:mm")} - {moment(modalData.end_date).format("HH:mm")}
                        <p className="duration">{modalData.duration} мин.</p>
                    </p>
                    <hr/>
                    <div className="teacher">
                        <p className="employee">{modalData.employee.name}</p>
                        <p className="employeeDesc">{modalData.employee.position.title}</p>
                    </div>
                    <hr/>
                    {
                        modalData.available_slots !== 0 && modalData.available_slots !== "unlimited"
                            ?
                            <p className="available_slots">Свободно: <b>{modalData.available_slots}</b> из <b>{modalData.capacity}</b>
                            </p>
                            : null
                    }
                    {
                        modalData.available_slots === "unlimited"
                            ? <p className="available_slots"><b>Без ограничений по местам</b></p>
                            : null
                    }
                    {
                        modalData.available_slots !== 0 && !moment(modalData.start_date).isBefore(Date.now()) && !userBookings.data.find(b => b.appointment_id === modalData.appointment_id)
                            ? <button className="booking"
                                      onClick={() => booking(modalData.appointment_id)}>Забронировать</button>
                            : null
                    }
                    <button className="close" onClick={() => setIsModalOpen(false)}>Закрыть</button>
                </div>
            </div>
            <div className="modal" style={{display: isModalLoginOpen ? "flex" : "none"}}
                 onClick={e => (e.currentTarget === e.target) && setIsModalLoginOpen(false)}>
                <div className="content">
                    <p>Для бронирования, пожалуйста, войдите</p>
                    <button className="booking" onClick={() => navigate("/lk")}>Вход\Регистрация</button>
                    <button className="close" onClick={() => setIsModalLoginOpen(false)}>Закрыть</button>
                </div>
            </div>
            <ToastContainer/>
        </div>
    </DocumentMeta>
};

export default GroupTimesPage;