import React, {useState} from "react";
import ArrowUpIcon from "../../../Images/Icons/ArrowUp.svg";
import ArrowLeftIcon from "../../../Images/Icons/ArrowLeft.svg";
import ArrowRightIcon from "../../../Images/Icons/ArrowRight.svg";
import Slider from "react-slick";
import Interior0Image from "../../../Images/Interior/0.png";
import Interior1Image from "../../../Images/Interior/1.png";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper";
import CMS from "../../../Api/CMS";

const InPlanMobile = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    return <div className={`inPlan ${isOpen ? "open" : ""}`}>
        <div className="text">
            <span className="close" onClick={() => setIsOpen(!isOpen)}>+</span>
            <p className="title" onClick={() => setIsOpen(!isOpen)}>{props.title}</p>
            <p className="desc" dangerouslySetInnerHTML={{__html: props.desc}}/>
        </div>
    </div>;
};

class AboutBlock extends React.Component {
    CMSAPI = new CMS();

    constructor(props) {
        super(props);

        this.state = {
            slickPage: 0,
            isLoadingMiniBlockInfo: true,
            miniBlockInfo: [{}],
        };

        this.CMSAPI.queryContentAll("miniBlockInfo").then((data) => {
            this.setState({
                miniBlockInfo: data.data,
                isLoadingMiniBlockInfo: false,
            });
        });
    }

    render() {
        return <div className="block plusPaddingTop about">
            <div className="main">
                <div className="text">
                    <p className="title">О КЛУБЕ</p>
                    <p className="desc" dangerouslySetInnerHTML={{__html: this.props.mainInfo?.about?.aboutClub}}/>
                </div>
                <button className="sliderControlButton" onClick={() => this.slider.slickPrev()}><img src={ArrowLeftIcon}
                                                                                                     alt=""/></button>
                <div className="imgSlider">
                    <Slider {...{
                        dots: false,
                        arrows: false,
                        slidesToScroll: 1,
                        slidesToShow: 1,
                        beforeChange: (current, next) => this.setState({slickPage: next}),
                    }} ref={slider => (this.slider = slider)}>
                        <img src={Interior0Image} alt=""/>
                        <img src={Interior1Image} alt=""/>
                        {
                            this.props.mainInfo?.about?.photoClub?.map((e, i) => {
                                return <img src={this.CMSAPI.getImageLink(e.path)} alt=""/>
                            })
                        }
                    </Slider>
                </div>
                <div className="sliderControl">
                    <div className="left" onClick={() => this.slider.slickPrev()}><img src={ArrowLeftIcon} alt=""/>
                    </div>
                    <div className="dots">
                        {
                            this.props.mainInfo?.about?.photoClub?.map((e, i) => {
                                return <span className={`dot ${this.state.slickPage === i ? "on" : ""}`}/>
                            })
                        }
                    </div>
                    <div className="right" onClick={() => this.slider.slickNext()}><img src={ArrowRightIcon} alt=""/>
                    </div>
                </div>
                <button className="sliderControlButton" onClick={() => this.slider.slickNext()}><img
                    src={ArrowRightIcon} alt=""/></button>
            </div>
            <div className="sliderMobile">
                {
                    !this.state.isLoadingMiniBlockInfo
                        ? this.state.miniBlockInfo.map((e, i) => {
                            return <InPlanMobile title={e.title.toUpperCase()} desc={e.text}/>
                        })
                        : null
                }
            </div>
            <div className="slider">
                <Swiper
                    modules={[Autoplay]}
                    slidesPerView={3}
                    autoplay={true}
                >

                    {
                        !this.state.isLoadingMiniBlockInfo
                            ? this.state.miniBlockInfo.map((e, i) => {
                                return <SwiperSlide>
                                    <div className="inPlan">
                                        <div className="text">
                                            <p className="title">{e.title.toUpperCase()}</p>
                                            <p dangerouslySetInnerHTML={{__html: e.text}}></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            })
                            : null
                    }
                </Swiper>
            </div>
        </div>;
    }
}

export default AboutBlock;