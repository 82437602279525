import React, {useEffect, useMemo, useState} from "react";
import DocumentMeta from "react-document-meta";

import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MapImage from "../Images/Map.png";

import {ReactComponent as WhatsAppImage} from "../Images/Social/WhatsApp.svg";
import {ReactComponent as TelegramImage} from "../Images/Social/Telegram.svg";
import {ReactComponent as VKImage} from "../Images/Social/VK.svg";
import TeacherBlock from "../Components/PageParts/TeacherBlock/TeacherBlock";
import AboutBlock from "../Components/PageParts/AboutBlock/AboutBlock";
import HiddenPhoneNumber from "../Components/Molecules/Contacts/HiddenPhoneNumber/HiddenPhoneNumber";
import {ReactComponent as OnlineSvgImage} from "../Images/Icons/Online.svg";
import ClubStatic from "../Api/ClubStatic";
import PlansBlock from "../Components/PageParts/PlansBlock/PlansBlock";

import CMS from "../Api/CMS";
import User from "../Api/User";
import {useSelector} from "react-redux";

const InteractiveInfoStepsComponent = (props) => {
    const CMSAPI = new CMS();

    return <div className="items">
        {
            props.steps ? props.steps.map((e, i) => {
                return <div className="item">
                    <div className="borderImg">
                        <div className="img">
                            <img src={CMSAPI.getImageLink(e.image.path)} alt=""/>
                        </div>
                    </div>
                    <p className="title">{e.title}</p>
                    <p className="desc" dangerouslySetInnerHTML={{__html: e.text}}/>
                </div>
            }) : null
        }
    </div>
};

const MainPage = () => {
    const CMSAPI = useMemo(() => new CMS(), []);
    const UserApi = useMemo(() => new User(), []);

    const seo = useSelector(state => state.content.seoArray.find(seoItem => seoItem.pageTag === "mainPage"));

    const [heightPage, setHeightPage] = useState(window.innerHeight - 100);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
    const [activeIndexSlider, setActiveIndexSlider] = useState(0);

    const [isModalPromotionOpen, setIsModalPromotionOpen] = useState(false);
    const {isAuth, userData} = useSelector(state => state.auth);

    const [isLoading, setIsLoading] = useState(true);
    const mainInfo = useSelector(state => state.content.contacts);
    const promotion = useSelector(state => state.content.promotion);
    const event = useSelector(state => state.content.contentForMainPage);
    const interactiveInfo = useSelector(state => state.content.interactiveInfo);

    const [numberIsInteractiveInfoLevel, setNumberIsInteractiveInfoLevel] = useState(null);

    useEffect(() => {
        if (heightPage !== (window.innerHeight - 100)) {
            if (window.innerWidth <= 600) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
            setHeightPage(window.innerHeight - 100);
        }

        if (isLoading) {
            setIsLoading(false);
        }
    }, [heightPage, isLoading, CMSAPI, UserApi, activeIndexSlider]);

    const clubStatic = new ClubStatic();

    const [clubWorkload, setClubWorkload] = useState(0);

    useEffect(() => {
        let clubWorkloadTimeout = setTimeout(() => {
            clubStatic.getClubWorkload("a79874c9-91a4-11e9-9a3e-6c2b598ba92b").then((d) => setClubWorkload(d.data.data.count));
        }, 200);
        let clubWorkloadInterval = setInterval(() => {
            clubStatic.getClubWorkload("a79874c9-91a4-11e9-9a3e-6c2b598ba92b").then((d) => setClubWorkload(d.data.data.count));
        }, 60000);

        return () => {
            clearInterval(clubWorkloadInterval);
            clearTimeout(clubWorkloadTimeout);
        };
    });

    const handlePromotionPurchase = (item, cost, type) => {
        window.location.href = `https://спорт-макс.рф/payment.php?title=${encodeURIComponent(item)}&summ=${cost}.00&email=${encodeURIComponent(userData.email)}&phone=${encodeURIComponent(userData.phone)}&type=${type}`;
    };

    return <DocumentMeta {...{
        title: seo.title,
        description: seo.description,
        meta: {
            charset: "utf-8",
            name: {
                keywords: seo.tags,
            },
        },
    }}>
        <div className="inPage">
            <div className="swiper-slide">
                <div className="item" style={{
                    backgroundImage: `url(${CMSAPI.getImageLink(event.bigImage?.path)})`,
                }}>
                    <div className="info" style={{height: isMobile ? heightPage - 50 : heightPage}}>
                        <div className="mobileSecondHeader">
                            <div className="social">
                                <a className="whatsAppSvg" href={mainInfo.whatsapp}><WhatsAppImage/></a>
                                <a className="telegramSvg" href={mainInfo.Telegram}><TelegramImage/></a>
                                <a className="vkImage" href={mainInfo.vk}><VKImage/></a>
                            </div>
                            <div className={`online ${clubWorkload === 0 ? "off" : null}`}>
                                <OnlineSvgImage/>
                                <div className="text">
                                    <p className="count">{clubWorkload} человек</p>
                                    <p className="info">сейчас в клубе</p>
                                </div>
                            </div>
                        </div>
                        <p className="title">{event.titleFirstStr}</p>
                        <p className="miniTitle">{event.titleSecontStr}</p>
                        <p className="text" dangerouslySetInnerHTML={{__html: event.text}}/>
                        {
                            event.showButton
                                ? <button style={{zIndex: "10000"}} className="orange"
                                          onClick={() => setIsModalPromotionOpen(true)}>{event.buttonText}</button>
                                : null
                        }
                    </div>
                </div>
            </div>
            {/*<SliderProgressBar progress={progressBarPositions[activeIndexSlider]} weekInfos={weekInfos} sliderImage={CMSAPI.getImageLink(event.sliderImage?.path)}/>*/}
            {/*<div className="interactiveInfo notMarginTop" style={{height: heightPage}}>*/}
            {/*    <button className={`level ${numberIsInteractiveInfoLevel === 0 ? "isOpen" : null}`} id="level0"*/}
            {/*            onClick={() => numberIsInteractiveInfoLevel === 0 ? setNumberIsInteractiveInfoLevel(null) : setNumberIsInteractiveInfoLevel(0)}*/}
            {/*    >*/}
            {/*        <img className="logo" src={CMSAPI.getImageLink(interactiveInfo.forNewbies?.image?.path)}*/}
            {/*             alt=""/>*/}
            {/*        <p className="label">{interactiveInfo.forNewbies?.title.toUpperCase()}</p>*/}
            {/*        <div className="content">*/}
            {/*            <p className="title">{interactiveInfo.forNewbies?.titleInText.toUpperCase()}</p>*/}
            {/*            <p className="text" dangerouslySetInnerHTML={{__html: interactiveInfo.forNewbies?.text}}/>*/}
            {/*            <InteractiveInfoStepsComponent steps={interactiveInfo.steps}/>*/}
            {/*        </div>*/}
            {/*    </button>*/}
            {/*    <button className={`level ${numberIsInteractiveInfoLevel === 1 ? "isOpen" : null}`} id="level1"*/}
            {/*            onClick={() => numberIsInteractiveInfoLevel === 1 ? setNumberIsInteractiveInfoLevel(null) : setNumberIsInteractiveInfoLevel(1)}*/}
            {/*    >*/}
            {/*        <img className="logo" src={CMSAPI.getImageLink(interactiveInfo.forLovers?.image?.path)} alt=""/>*/}
            {/*        <p className="label">{interactiveInfo.forLovers?.title.toUpperCase()}</p>*/}
            {/*        <div className="content">*/}
            {/*            <p className="title">{interactiveInfo.forLovers?.titleInText.toUpperCase()}</p>*/}
            {/*            <p className="text" dangerouslySetInnerHTML={{__html: interactiveInfo.forLovers?.text}}/>*/}
            {/*            <InteractiveInfoStepsComponent steps={interactiveInfo.steps}/>*/}
            {/*        </div>*/}
            {/*    </button>*/}
            {/*    <button className={`level ${numberIsInteractiveInfoLevel === 2 ? "isOpen" : null}`} id="level2"*/}
            {/*            onClick={() => numberIsInteractiveInfoLevel === 2 ? setNumberIsInteractiveInfoLevel(null) : setNumberIsInteractiveInfoLevel(2)}*/}
            {/*    >*/}
            {/*        <img className="logo" src={CMSAPI.getImageLink(interactiveInfo.forProfi?.image?.path)} alt=""/>*/}
            {/*        <p className="label">{interactiveInfo.forProfi?.title.toUpperCase()}</p>*/}
            {/*        <div className="content">*/}
            {/*            <p className="title">{interactiveInfo.forProfi?.titleInText.toUpperCase()}</p>*/}
            {/*            <p className="text" dangerouslySetInnerHTML={{__html: interactiveInfo.forProfi?.text}}/>*/}
            {/*            <InteractiveInfoStepsComponent steps={interactiveInfo.steps}/>*/}
            {/*        </div>*/}
            {/*    </button>*/}
            {/*</div>*/}
            <PlansBlock/>
            <TeacherBlock heightPage={heightPage}/>
            <AboutBlock heightPage={heightPage} mainInfo={mainInfo}/>
            <div className="block contacts">
                <div className="text">
                    <p className="title">КОНТАКТЫ</p>
                    <HiddenPhoneNumber number={mainInfo.phone ? mainInfo.phone : ""}/>
                    <a href={`mailto:${mainInfo.email}`} className="link">{mainInfo.email}</a>
                    <a href={mainInfo.mapLink} className="link">{mainInfo.address}</a>
                    <div className="social">
                        <a className="whatsAppSvg" href={mainInfo.whatsapp}><WhatsAppImage/></a>
                        <a className="telegramSvg" href={mainInfo.Telegram}><TelegramImage/></a>
                        <a className="vkImage" href={mainInfo.vk}><VKImage/></a>
                    </div>
                </div>
                <div className="map">
                    <img src={MapImage} alt="" onClick={() => window.location.href = mainInfo.mapLink}/>
                </div>
            </div>
            <div className="modal" style={{display: isModalPromotionOpen ? "flex" : "none", zIndex: "100000"}}
                 onClick={e => (e.currentTarget === e.target) && setIsModalPromotionOpen(false)}
            >
                <div className="content">
                    {
                        isAuth
                            ? <>
                                <p className="title">{promotion.title}</p>
                                <p className="text">{promotion.description}</p>
                                <p className="text">Цена: {promotion.cost} P.</p>
                                <button className="close"
                                        onClick={() => handlePromotionPurchase(promotion.title, promotion.cost, "promotion")}>Приобрести
                                </button>
                                <button className="close" onClick={() => setIsModalPromotionOpen(false)}>Закрыть
                                </button>
                            </>
                            : <>
                                <p className="title">{promotion.title}</p>
                                <p className="text">{promotion.description}</p>
                                <p className="text">Цена: {promotion.cost} P.</p>
                                <p className="text">Для участия в акции необходимо авторизоваться</p>
                                <button className="close"
                                        onClick={() => window.location.href = "/loginReg"}>Вход\Регистрация
                                </button>
                                <button className="close" onClick={() => setIsModalPromotionOpen(false)}>Закрыть
                                </button>
                            </>
                    }
                </div>
            </div>
        </div>
    </DocumentMeta>
};

export default MainPage;