import React, {useState} from "react";
import Switch from "react-switch";

import DayTimeIcon from "../../../Images/Icons/DayTime.svg";
import FullTimeIcon from "../../../Images/Icons/FullTime.svg";

const Plan = (props) => {
    const [isDayTime, setIsDayTime] = useState(true);
    const [isModalQOpen, setIsModalQOpen] = useState(false);
    const [isModalMoreInfoOpen, setIsModalMoreInfoOpen] = useState(false);

    const handlePlanPurchase = (item, cost, duration, durationType, type) => {
        window.location.href = `https://спорт-макс.рф/payment.php?title=${encodeURIComponent(item)}&summ=${cost}.00&email=${encodeURIComponent(props.userData.email)}&phone=${encodeURIComponent(props.userData.phone)}&type=${type}`;
    };

    return <div className="plan">
        <div className="switcher">
            <div className="timePart">
                <img src={DayTimeIcon} alt=""/><p className="label">Дневной</p><p className="duration">с 6 до 17</p>
            </div>
            <div className="switch"><Switch onChange={() => setIsDayTime(!isDayTime)} uncheckedIcon={false}
                                            checkedIcon={false} offColor="#D9D9D9" onColor="#D9D9D9"
                                            offHandleColor="#003F93" onHandleColor="#003F93" checked={!isDayTime}/>
            </div>
            <div className="timePart">
                <img src={FullTimeIcon} alt=""/><p className="label">Полный</p><p className="duration">с 6 до 24</p>
            </div>
        </div>
        <p className="desc">Выбрать абонемент по времени суток</p>
        <p className="time">{props.time}</p>
        {
            props.oldDayCost
                ? <p className="oldCost">{isDayTime ? props.oldDayCost : props.oldFullCost} ₽</p>
                : null
        }
        <p className="cost">{isDayTime ? props.dayCost : props.fullCost} ₽</p>
        <a className="link blue" href="javascript:void(0)" onClick={() => setIsModalMoreInfoOpen(true)}>подробнее...</a>
        <button className="primary" onClick={props.isClickable ? () => setIsModalQOpen(true) : props.onClick}>оплатить
        </button>
        <div className="modal" style={{display: isModalQOpen ? "flex" : "none"}}
             onClick={e => (e.currentTarget === e.target) && setIsModalQOpen(false)}
        >
            <div className="content">
                <p className="title">Провести оплату?</p>
                <button className="close" onClick={() => {
                    setIsModalQOpen(false);
                    handlePlanPurchase(!props.time.includes("год") ? props.time : "12 месяцев", isDayTime ? props.dayCost : props.fullCost, !props.time.includes("ГОД") ? Number(props.time[0]) : 1, !props.time.includes("ГОД") ? "months" : "years", isDayTime ? "day" : "full");
                }}>Да
                </button>
                <button className="close" onClick={() => setIsModalQOpen(false)}>Нет</button>
            </div>
        </div>
        <div className="modal" style={{display: isModalMoreInfoOpen ? "flex" : "none"}}
             onClick={e => (e.currentTarget === e.target) && setIsModalMoreInfoOpen(false)}
        >
            <div className="content">
                <p className="title">{props.time}</p>
                {/*<p>{props.dayCost} ₽ / {props.fullCost} ₽</p>*/}
                <p style={{textAlign: "center"}}>{props.textInModalPay}</p>
                <br/>
                <div dangerouslySetInnerHTML={{__html: props.description}}/>
                <button className="close" onClick={() => {setIsModalQOpen(true); setIsModalMoreInfoOpen(false)}}>Оплатить</button>
                <button className="close" onClick={() => setIsModalMoreInfoOpen(false)}>Закрыть</button>
            </div>
        </div>
    </div>
};

export default Plan;