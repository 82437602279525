import React, {useEffect, useState} from "react";
import CMS from "../Api/CMS";
import {useSearchParams} from "react-router-dom";
import DocumentMeta from "react-document-meta";
import Frame from "./Frame.svg";
import Insta from "../Images/Social/Insta.png";
import {useSelector} from "react-redux";
import moment from "moment";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination, Navigation} from "swiper";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {sendMail} from "../Api/SMTP";

const TeacherPage = () => {
    const CMSAPI = new CMS();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const teacher = useSelector(state => state.content.employees.find(trainer => trainer._id === id));
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalNewReviewOpen, setIsModalNewReviewOpen] = useState(false);
    const [text, setText] = useState("");
    const {isAuth, userData} = useSelector(state => state.auth);
    const {name, last_name, phone, email} = userData;

    const handleAddRecordToTeacher = () => {
        CMSAPI.addItem("recordToTeacher", {
            numberPhone: phone,
            email: email,
            userName: name + " " + last_name,
            teather: {_id: teacher._id},
        }).then(() => {
            sendMail("Новая персональная запись к трененру", `Запись к ${teacher.name} ${teacher.lastName}`,
                `
                    Новая персональная запись к трененру ${teacher.name} ${teacher.lastName} <br/>
                    Имя клиента: ${name + " " + last_name} <br/>
                    Номер телефона: ${phone}
                `);
            setIsModalOpen(false);
        });
    };

    const handleAddNewReview = () => {
        const newReview = {
            name: name + " " + last_name,
            datatime: moment().format("YYYY-MM-DD"),
            text: text,
        };
        let reviews = Object.assign([], teacher.reviews);
        reviews.push(newReview);
        CMSAPI.addItem("employees", {
            _id: teacher._id,
            reviews: reviews,
        }).then(() => {
            sendMail("Новый отзыв о тренере", `Новый отзыв о тренере ${teacher.name} ${teacher.lastName}`,
                `
                    Новый отзыв о тренере ${teacher.name} ${teacher.lastName} <br/>
                    Имя клиента: ${name + " " + last_name} <br/>
                    Текст: ${text}
                `)
            setIsModalNewReviewOpen(false);
        });
    };

    return !isLoading
        ? <DocumentMeta {...{
            title: teacher.name + " " + teacher.lastName,
        }}>
            <div className="inPage">
                <div className="block trainerPage">
                    <div className="info">
                        <div className="trainerItem">
                            <div className="headerBlock">
                                <div className="imageBlock">
                                    <img className="frame" src={Frame} alt=""/>
                                    <img className="image"
                                         src={CMSAPI.getImageLink(teacher.photo ? teacher.photo.path : teacher.photo.path)}
                                         alt={teacher.name}/>
                                </div>
                                <div className="text">
                                    <div className="main">
                                        <div className="t">
                                            <p className="title">{teacher.name} {teacher.lastName}</p>
                                            <button className="orange"
                                                    onClick={() => setIsModalOpen(true)}>КО МНЕ НА
                                                ТРЕНИРОВКУ
                                            </button>
                                        </div>
                                    </div>
                                    {
                                        teacher.inst
                                            ? <div className="instBlock">
                                                <img src={Insta} alt=""/>
                                                <a href={`https://instagram.com/${teacher.inst}`}
                                                   className="link inst">@{teacher.inst}</a>
                                            </div>
                                            : null
                                    }
                                    <p className="category">
                                        {teacher.category ? teacher.category : "Тренер"}
                                    </p>
                                    <div className="desc"
                                         dangerouslySetInnerHTML={{__html: teacher.description}}/>
                                </div>
                            </div>
                            <div className="photoRow">
                                {
                                    teacher.bigPic
                                        ? teacher.bigPic.map((pic, key) => {
                                            return <img
                                                src={CMSAPI.getImageLink(pic.path)}
                                                alt={teacher.name}/>
                                        })
                                        : null
                                }
                            </div>
                            <div className="photoRowMobile">
                                <Swiper
                                    modules={[Autoplay, Pagination, Navigation]}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    slidesPerView={1}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    centeredSlides={true}
                                    spaceBetween={30}
                                >
                                    {
                                        teacher.bigPic
                                            ? teacher.bigPic.map((pic, key) => {
                                                return <SwiperSlide>
                                                    <img src={CMSAPI.getImageLink(pic.path)} alt={teacher.name} key={key}/>
                                                </SwiperSlide>
                                            })
                                            : null
                                    }
                                </Swiper>
                            </div>
                            <div className="reviews">
                                {
                                    teacher.reviews.map((e, i) => {
                                        return <div className="review">
                                            <div className="main">
                                                <p className="time">{e.datatime}</p>
                                                <p className="name">{e.name}</p>
                                            </div>
                                            <p className="desc">
                                                {e.text}
                                            </p>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="buttons">
                                <button className="orange" onClick={() => setIsModalNewReviewOpen(true)}>ОСТАВИТЬ
                                    ОТЗЫВ
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" style={{display: isModalOpen ? "flex" : "none"}}
                     onClick={e => (e.currentTarget === e.target) && setIsModalOpen(false)}
                >
                    <div className="content">
                        {
                            isAuth
                                ? <>
                                    <p className="title">Вы желаете записаться
                                        к {teacher.name} {teacher.lastName}?</p>
                                    <button className="close" onClick={handleAddRecordToTeacher}>Да</button>
                                    <button className="close" onClick={() => setIsModalOpen(false)}>Нет</button>
                                </>
                                : <>
                                    <p className="title">Для записи необходимо авторизоваться</p>
                                    <button className="close"
                                            onClick={() => window.location.href = "/loginReg"}>Вход\Регистрация
                                    </button>
                                    <button className="close" onClick={() => setIsModalOpen(false)}>Закрыть
                                    </button>
                                </>
                        }
                    </div>
                </div>
                <div className="modal" style={{display: isModalNewReviewOpen ? "flex" : "none"}}
                     onClick={e => (e.currentTarget === e.target) && setIsModalNewReviewOpen(false)}
                >
                    <div className="content">
                        {
                            isAuth
                                ? <>
                                    <p className="title">Новый отзыв
                                        для {teacher.name} {teacher.lastName}?</p>
                                    <textarea cols={10} rows={5} placeholder="Ваш отзыв..." onChange={(e) => setText(e.target.value)}/>
                                    <button className="close" onClick={handleAddNewReview}>Да
                                    </button>
                                    <button className="close" onClick={() => setIsModalNewReviewOpen(false)}>Нет
                                    </button>
                                </>
                                : <>
                                    <p className="title">Для написания отзыва необходимо авторизоваться</p>
                                    <button className="close"
                                            onClick={() => window.location.href = "/loginReg"}>Вход\Регистрация
                                    </button>
                                    <button className="close" onClick={() => setIsModalOpen(false)}>Закрыть
                                    </button>
                                </>
                        }
                    </div>
                </div>
            </div>
        </DocumentMeta>
        : null
};

export default TeacherPage;