import React, {Component} from 'react';
import * as d3 from "d3";

class SliderProgressBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            prevSliderImage: "",
        };
    }

    componentDidMount() {
        d3.select(".progressBar#sliderMainPage").selectAll("*").remove();
        this.drawChart(this.props.progress);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.sliderImage !== this.state.prevSliderImage) {
            this.setState({prevSliderImage: this.props.sliderImage});
            this.drawUpdate(this.props.progress);
        }
        if (prevProps.progress !== this.props.progress) {
            this.drawUpdate(this.props.progress);
        }
    }

    componentWillUnmount() {
        d3.select(".progressBar#sliderMainPage").selectAll("*").remove();
    }

    drawChart(progress = 30) {
        const svg = d3.select(".progressBar#sliderMainPage").append("svg").attr("width", window.innerWidth - 70).attr("height", 150);

        let lineWidth = window.innerWidth - 100;
        let circlePosition = [25, ((lineWidth) * 0.33), ((lineWidth) * 0.66), ((lineWidth))];

        svg.append("rect").style("fill", "#161616").attr("x", 15).attr("y", 105).attr("width", lineWidth).attr("height", 20).style("stroke", "#003F93");
        svg.append("rect").attr("id", "progressBarContent").attr("x", 10 + 1).attr("y", 105 + 1).style("fill", "#003F93").attr("width", (lineWidth / 100) * progress).attr("height", 20 - 2);
        svg.append("circle").attr("id", "circle0").attr("cx", circlePosition[0]).attr("cy", 115).attr("r", 20).style("fill", progress >= 0 ? "#003F93" : "#161616").style("stroke", "#003F93").on("mouseover", () => this.handleMouseOver("One")).on("mouseout", () => this.handleMouseOut("One"));
        svg.append("text").text("1").attr("x", circlePosition[0] - 5).attr("y", 122.5).attr("text-anchor", "center").style("font-size", "20px").style("font-weight", "700").style("fill", "#fff").on("mouseover", () => this.handleMouseOver("One")).on("mouseout", () => this.handleMouseOut("One"));
        svg.append("circle").attr("id", "circle1").attr("cx", circlePosition[1]).attr("cy", 115).attr("r", 20).style("fill", progress >= 33 ? "#003F93" : "#161616").style("stroke", "#003F93").on("mouseover", () => this.handleMouseOver("Two")).on("mouseout", () => this.handleMouseOut("Two"));
        svg.append("text").text("2").attr("x", circlePosition[1] - 5).attr("y", 122.5).attr("text-anchor", "center").style("font-size", "20px").style("font-weight", "700").style("fill", "#fff").on("mouseover", () => this.handleMouseOver("Two")).on("mouseout", () => this.handleMouseOut("Two"));
        svg.append("circle").attr("id", "circle2").attr("cx", circlePosition[2]).attr("cy", 115).attr("r", 20).style("fill", progress >= 66 ? "#003F93" : "#161616").style("stroke", "#003F93").on("mouseover", () => this.handleMouseOver("Three")).on("mouseout", () => this.handleMouseOut("Three"));
        svg.append("text").text("3").attr("x", circlePosition[2] - 5).attr("y", 122.5).attr("text-anchor", "center").style("font-size", "20px").style("font-weight", "700").style("fill", "#fff").on("mouseover", () => this.handleMouseOver("Three")).on("mouseout", () => this.handleMouseOut("Three"));
        svg.append("circle").attr("id", "circle3").attr("cx", circlePosition[3]).attr("cy", 115).attr("r", 20).style("fill", progress >= 99 ? "#003F93" : "#161616").style("stroke", "#003F93").on("mouseover", () => this.handleMouseOver("Four")).on("mouseout", () => this.handleMouseOut("Four"));
        svg.append("text").text("4").attr("x", circlePosition[3] - 7.5).attr("y", 122.5).attr("text-anchor", "center").style("font-size", "20px").style("font-weight", "700").style("fill", "#fff").on("mouseover", () => this.handleMouseOver("Four")).on("mouseout", () => this.handleMouseOut("Four"));
        svg.append("svg:image").attr("id", "eventIcon").attr("xlink:href", this.props.sliderImage).attr("x", (lineWidth / 100) * progress).attr("y", this.props.sliderAlign === "center" ? 96.5 : 50);

        svg.append("rect").attr("id", "weekOne").attr("x", circlePosition[0]).attr("y", 0).attr("width", 200).attr("height", 80).style("fill", "#fff7").style("opacity", "0").attr("rx", "6");
        svg.append("text").attr("id", "titleOne").text("1 неделя:").style("fill", "#fff").attr("x", circlePosition[0] + 20).attr("y", 30).style("font-size", "12px").attr("width", 100).attr("height", 20).style("opacity", "0");
        svg.append("text").attr("id", "textOne").text(this.props.weekInfos[0].text).style("fill", "#aaa").attr("x", circlePosition[0] + 20).attr("y", 45).style("font-size", "12px").attr("width", 100).attr("height", 50).style("opacity", "0");
        svg.append("text").attr("id", "text2One").text(this.props.weekInfos[0].text2).style("fill", "#aaa").attr("x", circlePosition[0] + 20).attr("y", 60).style("font-size", "12px").attr("width", 100).attr("height", 50).style("opacity", "0");

        svg.append("rect").attr("id", "weekTwo").attr("x", circlePosition[1]).attr("y", 0).attr("width", 200).attr("height", 80).style("fill", "#fff7").style("opacity", "0").attr("rx", "6");
        svg.append("text").attr("id", "titleTwo").text("2 неделя:").style("fill", "#fff").attr("x", circlePosition[1] + 20).attr("y", 30).style("font-size", "12px").attr("width", 100).attr("height", 20).style("opacity", "0");
        svg.append("text").attr("id", "textTwo").text(this.props.weekInfos[1].text).style("fill", "#aaa").attr("x", circlePosition[1] + 20).attr("y", 45).style("font-size", "12px").attr("width", 100).attr("height", 50).style("opacity", "0");
        svg.append("text").attr("id", "text2Two").text(this.props.weekInfos[1].text2).style("fill", "#aaa").attr("x", circlePosition[1] + 20).attr("y", 60).style("font-size", "12px").attr("width", 100).attr("height", 50).style("opacity", "0");

        svg.append("rect").attr("id", "weekThree").attr("x", circlePosition[2]).attr("y", 0).attr("width", 200).attr("height", 80).style("fill", "#fff7").style("opacity", "0").attr("rx", "6");
        svg.append("text").attr("id", "titleThree").text("3 неделя:").style("fill", "#fff").attr("x", circlePosition[2] + 20).attr("y", 30).style("font-size", "12px").attr("width", 100).attr("height", 20).style("opacity", "0");
        svg.append("text").attr("id", "textThree").text(this.props.weekInfos[2].text).style("fill", "#aaa").attr("x", circlePosition[2] + 20).attr("y", 45).style("font-size", "12px").attr("width", 100).attr("height", 50).style("opacity", "0");
        svg.append("text").attr("id", "text2Three").text(this.props.weekInfos[2].text2).style("fill", "#aaa").attr("x", circlePosition[2] + 20).attr("y", 60).style("font-size", "12px").attr("width", 100).attr("height", 50).style("opacity", "0");

        svg.append("rect").attr("id", "weekFour").attr("x", circlePosition[3] - 200).attr("y", 0).attr("width", 200).attr("height", 80).style("fill", "#fff7").style("opacity", "0").attr("rx", "6");
        svg.append("text").attr("id", "titleFour").text("4 неделя:").style("fill", "#fff").attr("x", circlePosition[3] - 180).attr("y", 30).style("font-size", "12px").attr("width", 100).attr("height", 20).style("opacity", "0");
        svg.append("text").attr("id", "textFour").text(this.props.weekInfos[3].text).style("fill", "#aaa").attr("x", circlePosition[3] - 180).attr("y", 45).style("font-size", "12px").attr("width", 100).attr("height", 50).style("opacity", "0");
        svg.append("text").attr("id", "text2Four").text(this.props.weekInfos[3].text2).style("fill", "#aaa").attr("x", circlePosition[3] - 180).attr("y", 60).style("font-size", "12px").attr("width", 100).attr("height", 50).style("opacity", "0");
    }

    handleMouseOver (id) {
        d3.select("#week" + id).style("opacity", "1");
        d3.select("#title" + id).style("opacity", "1");
        d3.select("#text" + id).style("opacity", "1");
        d3.select("#text2" + id).style("opacity", "1");
    }

    handleMouseOut (id) {
        d3.select("#week" + id).style("opacity", "0");
        d3.select("#title" + id).style("opacity", "0");
        d3.select("#text" + id).style("opacity", "0");
        d3.select("#text2" + id).style("opacity", "0");
    }

    drawUpdate(progress = 0) {
        let lineWidth = window.innerWidth - 100;
        d3.select(".progressBar#sliderMainPage").select("rect#progressBarContent").transition().attr("width", (lineWidth / 100) * progress);
        d3.select(".progressBar#sliderMainPage").select("#eventIcon").transition().attr("x", (lineWidth / 100) * progress);
        d3.select(".progressBar#sliderMainPage").select("#circle1").transition().style("fill", progress >= 33 ? "#003F93" : "#161616");
        d3.select(".progressBar#sliderMainPage").select("#circle2").transition().style("fill", progress >= 66 ? "#003F93" : "#161616");
        d3.select(".progressBar#sliderMainPage").select("#circle3").transition().style("fill", progress >= 99 ? "#003F93" : "#161616");

        d3.select("#eventIcon").attr("xlink:href", this.state.prevSliderImage);

        d3.select("#textOne").text(this.props.weekInfos[0].text);
        d3.select("#text2One").text(this.props.weekInfos[0].text2);
        d3.select("#textTwo").text(this.props.weekInfos[1].text);
        d3.select("#text2Two").text(this.props.weekInfos[1].text2);
        d3.select("#textThree").text(this.props.weekInfos[2].text);
        d3.select("#text2Three").text(this.props.weekInfos[2].text2);
        d3.select("#textFour").text(this.props.weekInfos[3].text);
        d3.select("#text2Four").text(this.props.weekInfos[3].text2);

        if (this.state.prevSliderImage) {

        }
    }

    render() {
        return <div className="progressBar" id="sliderMainPage">

        </div>
    }
}

export default SliderProgressBar;