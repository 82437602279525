import React, {useEffect, useState} from "react";
import CMS from "../Api/CMS";
import DocumentMeta from "react-document-meta";
import {useSelector} from "react-redux";

const PromotionsList = () => {
    const CMSAPI = new CMS();
    const promotions = useSelector(state => state.content.promotions);
    const [isLoading] = useState(false);
    const seo = useSelector(state => state.content.seoArray.find(seoItem => seoItem.pageTag === "promotionsList"));

    return <DocumentMeta {...{
        title: seo.title,
        description: seo.description,
        meta: {
            charset: "utf-8",
            name: {
                keywords: seo.tags,
            },
        },
    }}>
        <div className="inPage">
            <div className="block">
                {
                    isLoading
                        ? <p>Loading...</p>
                        : <div className="promotionsList">
                            {
                                promotions.map((p, i) => {
                                    return <div className={`promotion ${!p.isActive ? "isDisabled" : null}`}>
                                        <img src={CMSAPI.getImageLink(p.bigPic?.path)} alt=""/>
                                    </div>
                                })
                            }
                        </div>
                }
            </div>
        </div>
    </DocumentMeta>
};

export default PromotionsList;