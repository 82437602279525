import React, {useEffect, useState} from "react";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import './App.css';
import IndexPage from "./Pages/Index";
import GroupTimesPage from "./Pages/Table/GroupTimes";
import GroupTimesPageInOneDay from "./Pages/Table/GroupTimesInOneDay";
import LoginRegPage from "./Pages/Table/LoginReg";
import LkPage from "./Pages/Table/Lk";
import MainPage from "./Pages/MainPage";
import Fortuna from "./Components/PageParts/Fortuna/Fortuna";
import ForNovicePage from "./Pages/ForNovice";
import PlansPage from "./Pages/Plans";
import AboutPage from "./Pages/About";
import ContactsPage from "./Pages/Contacts";
import TeachersPage from "./Pages/Teachers";
import {PaymentFail, PaymentSuccess} from "./Pages/Payment";
import MainPageDuble from "./Pages/MainPageDuble";
import PromotionsList from "./Pages/PromotionsList";
import TeacherPage from "./Pages/Teacher";

import {Provider} from "react-redux";
import store from "./Store";

import BgFor404Img from "./Images/bgFor404.png";

const Page404 = () => {
    const [paramsEl, setParamsEl] = useState({
        height: 0,
        top: 0,
        left: 0,
    });

    useEffect(() => {
        setParamsEl({
            height: document.querySelector(".content").getBoundingClientRect().height,
            top: document.querySelector(".content").getBoundingClientRect().top,
            left: document.querySelector(".content").getBoundingClientRect().left,
        })
    });

    return <div className="page-error" style={{

    }}>
        <div className="content" style={{
            backgroundImage: `url(${BgFor404Img})`,
            backgroundSize: `cover`,
            width: paramsEl.height + "px",
        }}>
            <div className="shadow" style={{
                top: paramsEl.top + "px",
                left: paramsEl.left + "px",
                width: paramsEl.height + "px",
                height: paramsEl.height + "px",
            }}/>
            <p className="code">404</p>
            <p className="title">Страница не найдена</p>
            <button className="primary" onClick={() => window.location.href = "/"}>НА ГЛАВНУЮ</button>
        </div>
    </div>
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <IndexPage/>,
        errorElement: <Page404/>,
        children: [
            {
                path: "",
                element: <MainPage/>
            },
            {
                path: "groupTimes",
                element: <GroupTimesPage/>,
            },
            {
                path: "groupTimesInOneDay",
                element: <GroupTimesPageInOneDay/>,
            },
            {
                path: "loginReg",
                element: <LoginRegPage/>,
            },
            {
                path: "lk",
                element: <LkPage/>,
            },
            {
                path: "fortuna",
                element: <Fortuna/>,
            },
            {
                path: "forNovice",
                element: <ForNovicePage/>,
            },
            {
                path: "plans",
                element: <PlansPage/>,
            },
            {
                path: "about",
                element: <AboutPage/>,
            },
            {
                path: "contacts",
                element: <ContactsPage/>,
            },
            {
                path: "trainers",
                element: <TeachersPage/>,
            },
            {
                path: "paymentSuccess.php",
                element: <PaymentSuccess/>,
            },
            {
                path: "paymentFail",
                element: <PaymentFail/>
            },
            {
                path: "mainPageDuble",
                element: <MainPageDuble/>,
            },
            {
                path: "promotions",
                element: <PromotionsList/>,
            },
            {
                path: "trainer",
                element: <TeacherPage/>,
            },
        ],
    },
]);

const App = () => {
    return <Provider store={store}>
        <RouterProvider router={router}/>
    </Provider>;
};

export default App;
