import axios from "axios";
import moment from "moment";

class Query {
    constructor(api = "97be8c0a-5f84-4eff-a18a-3f9f095535a4", token = "Basic d2ViOjEyMTM1MQ==", addressServer = "/Fitness_PROF", apiPath = "/hs/api/v3") {
        this.api = api;
        this.token = token;
        this.addressServer = addressServer;
        this.apiPath = apiPath;
        this.url = this.addressServer + this.apiPath;
    }

    static serialize(obj) {
        let str = [];
        for (let p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    static convertDateTo1C(date) {
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }

    urlConstructor(url = "", data = {}) {
        return this.url + url + (!data ? "" : "?") + Query.serialize(data)  + "?v=1".replace("??", "?");
    }

    query(url, data, method = "GET", userToken = "") {
        if (method === "GET") {
            return axios.get(this.urlConstructor(url, data), {
                headers: {
                    apikey: this.api,
                    authorization: this.token,
                    usertoken: userToken,
                }
            });
        } else if (method === "POST") {
            return axios.post(this.urlConstructor(url, data), data, {
                headers: {
                    apikey: this.api,
                    authorization: this.token,
                    usertoken: userToken,
                }
            });
        } else if (method === "DELETE") {
            return axios.delete(this.urlConstructor(url, data), {
                headers: {
                    apikey: this.api,
                    authorization: this.token,
                    usertoken: userToken,
                }
            });
        }
    }
}

export default Query;