import React from "react";
import {Link} from "react-router-dom";

export const PaymentSuccess = () => {
    return <div className="inPage">
        <div className="block">
            <p className="title">Оплата успешно прошла</p>
            <Link to="/lk">В личный кабинет</Link>
        </div>
    </div>
};

export const PaymentFail = () => {
    return <div className="inPage">
        <div className="block">
            <p className="title">Оплата не успешна</p>
            <Link to="/">На главную</Link>
        </div>
    </div>
};