import React from "react";
import Slider from "react-slick";

import ArrowUpIcon from "../../../Images/Icons/ArrowUp.svg";
import ArrowDownIcon from "../../../Images/Icons/ArrowDown.svg";

import GermanPersonalImage from "../../../Images/Personal/German.png";
import AntonPersonalImage from "../../../Images/Personal/Anton.png";
import AlexeyPersonalImage from "../../../Images/Personal/Alexey.png";
import ArrowRightIcon from "../../../Images/Icons/ArrowRight.svg";
import ArrowLeftIcon from "../../../Images/Icons/ArrowLeft.svg";
import moment from "moment";

import CMS from "../../../Api/CMS";
import User from "../../../Api/User";
import {hookForClassComponent} from "../../../Store/hookForClassComponent";
import {sendMail} from "../../../Api/SMTP";

class TeacherBlock extends React.Component {
    setting = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
    };

    CMSAPI = new CMS();
    UserApi = new User();

    constructor(props) {
        super(props);
        this.state = {
            teachers: [
                {
                    firstName: "ГЕРМАН",
                    fullName: "ВОЛКОВ ГЕРМАН",
                    category: "Персональный тренер",
                    img: GermanPersonalImage,
                    desc: "Герман – профессиональный футболист, окончивший училище олимпийского резерва в Донецке. После получения юридического образования вернулся в спорт и активно занялся тренерской деятельностью, параллельно повышая свою квалификацию на профильных курсах. С 2015 года является инструктором групповых программ и персональным тренером. Больше двух лет курировал групповые программы. Герман – чемпион региональных соревнований по бодибилдингу, призёр различных соревнований по футболу, Crossfit, бодибилдингу и фитнесу. При работе с клиентом стремится создать дружественную атмосферу и уделять человеку много времени. Его уникальная методика включает в себя построение перспективного календарного плана, анализ всех параметров организма, тщательное слежение за динамикой изменений, учёт режима питания и восстановления, индивидуальный подход к тренировкам, поддержку и постоянный контакт с клиентом. «Моя цель – привести клиента к результату, который он хочет увидеть».",
                    reviews: [
                        {
                            time: "20.01.2022",
                            name: "Надежда",
                            desc: "Для меня это лучший тренер которого я знаю и видела! Он помогает мне во всем проделали путь от новичка до уверенного любителя. Занимаюсь 2 раза в неделю.",
                        },
                        {
                            time: "20.01.2022",
                            name: "Надежда",
                            desc: "Для меня это лучший тренер которого я знаю и видела! Он помогает мне во всем проделали путь от новичка до уверенного любителя. Занимаюсь 2 раза в неделю.",
                        },
                    ],
                },
                {
                    firstName: "АНТОН",
                    fullName: "АНТОН МИСЬ",
                    category: "Персональный тренер",
                    img: AntonPersonalImage,
                    desc: "Антон тренирует больше семи лет, пройдя курсы FPA (Ассоциация профессионалов фитнеса) и Life Fitness. Спортом занимается с детства, любит пауэрлифтинг, бокс и кёрлинг. Считает, что в своём деле надо быть профессионалом. В работе с клиентами придерживается принципа открытости и прозрачности деятельности, обязательно ищет точки соприкосновения и готов поддерживать подопечных круглосуточно.\n" +
                        "Помимо наставничества разрабатывает индивидуальные планы питания.\n" +
                        "\n" +
                        "«Полная отдача должна быть с обеих сторон. Тогда будет соответствующий результат»",
                    reviews: [
                        {
                            time: "20.01.2022",
                            name: "Надежда",
                            desc: "Для меня это лучший тренер которого я знаю и видела! Он помогает мне во всем проделали путь от новичка до уверенного любителя. Занимаюсь 2 раза в неделю.",
                        },
                        {
                            time: "20.01.2022",
                            name: "Надежда",
                            desc: "Для меня это лучший тренер которого я знаю и видела! Он помогает мне во всем проделали путь от новичка до уверенного любителя. Занимаюсь 2 раза в неделю.",
                        },
                    ],
                },
                {
                    firstName: "АЛЕКСЕЙ",
                    fullName: "АЛЕКСЕЙ МИСЬ",
                    category: "Персональный тренер",
                    img: AlexeyPersonalImage,
                    desc: "Алексей, помимо высшего образования, получил сертификат как специалист в области фитнеса и бодибилдинга, прошёл курсы FPA (Ассоциация профессионалов фитнеса) и Life Fitness. Стаж работы – более десяти лет. Является мастером спорта по бодибилдингу и КМС по гиревому спорту. В 2019 году стал чемпионом кубка NBC в категории свыше 92 кг, в 2020 году – чемпионом России WFF. Несколько раз был призёром Кубка Москвы и Московской области, чемпионата России.\n" +
                        "Алексей анализирует желания клиента, чтобы детально сформировать для него план питания и тренировок.\n" +
                        "\n" +
                        "«Только полное соблюдение режима питания, тренировок и восстановления приведёт к успеху»",
                    reviews: [
                        {
                            time: "20.01.2022",
                            name: "Надежда",
                            desc: "Для меня это лучший тренер которого я знаю и видела! Он помогает мне во всем проделали путь от новичка до уверенного любителя. Занимаюсь 2 раза в неделю.",
                        },
                        {
                            time: "20.01.2022",
                            name: "Надежда",
                            desc: "Для меня это лучший тренер которого я знаю и видела! Он помогает мне во всем проделали путь от новичка до уверенного любителя. Занимаюсь 2 раза в неделю.",
                        },
                    ],
                },
                {
                    firstName: "ГЕРМАН",
                    fullName: "ВОЛКОВ ГЕРМАН",
                    category: "Персональный тренер",
                    img: GermanPersonalImage,
                    desc: "Герман – профессиональный футболист, окончивший училище олимпийского резерва в Донецке. После получения юридического образования вернулся в спорт и активно занялся тренерской деятельностью, параллельно повышая свою квалификацию на профильных курсах. С 2015 года является инструктором групповых программ и персональным тренером. Больше двух лет курировал групповые программы. Герман – чемпион региональных соревнований по бодибилдингу, призёр различных соревнований по футболу, Crossfit, бодибилдингу и фитнесу. При работе с клиентом стремится создать дружественную атмосферу и уделять человеку много времени. Его уникальная методика включает в себя построение перспективного календарного плана, анализ всех параметров организма, тщательное слежение за динамикой изменений, учёт режима питания и восстановления, индивидуальный подход к тренировкам, поддержку и постоянный контакт с клиентом. «Моя цель – привести клиента к результату, который он хочет увидеть».",
                    reviews: [
                        {
                            time: "20.01.2022",
                            name: "Надежда",
                            desc: "Для меня это лучший тренер которого я знаю и видела! Он помогает мне во всем проделали путь от новичка до уверенного любителя. Занимаюсь 2 раза в неделю.",
                        },
                        {
                            time: "20.01.2022",
                            name: "Надежда",
                            desc: "Для меня это лучший тренер которого я знаю и видела! Он помогает мне во всем проделали путь от новичка до уверенного любителя. Занимаюсь 2 раза в неделю.",
                        },
                    ],
                },
                {
                    firstName: "АНТОН",
                    fullName: "АНТОН МИСЬ",
                    category: "Персональный тренер",
                    img: AntonPersonalImage,
                    desc: "Антон тренирует больше семи лет, пройдя курсы FPA (Ассоциация профессионалов фитнеса) и Life Fitness. Спортом занимается с детства, любит пауэрлифтинг, бокс и кёрлинг. Считает, что в своём деле надо быть профессионалом. В работе с клиентами придерживается принципа открытости и прозрачности деятельности, обязательно ищет точки соприкосновения и готов поддерживать подопечных круглосуточно.\n" +
                        "Помимо наставничества разрабатывает индивидуальные планы питания.\n" +
                        "\n" +
                        "«Полная отдача должна быть с обеих сторон. Тогда будет соответствующий результат»",
                    reviews: [
                        {
                            time: "20.01.2022",
                            name: "Надежда",
                            desc: "Для меня это лучший тренер которого я знаю и видела! Он помогает мне во всем проделали путь от новичка до уверенного любителя. Занимаюсь 2 раза в неделю.",
                        },
                        {
                            time: "20.01.2022",
                            name: "Надежда",
                            desc: "Для меня это лучший тренер которого я знаю и видела! Он помогает мне во всем проделали путь от новичка до уверенного любителя. Занимаюсь 2 раза в неделю.",
                        },
                    ],
                },
                {
                    firstName: "АЛЕКСЕЙ",
                    fullName: "АЛЕКСЕЙ МИСЬ",
                    category: "Персональный тренер",
                    img: AlexeyPersonalImage,
                    desc: "Алексей, помимо высшего образования, получил сертификат как специалист в области фитнеса и бодибилдинга, прошёл курсы FPA (Ассоциация профессионалов фитнеса) и Life Fitness. Стаж работы – более десяти лет. Является мастером спорта по бодибилдингу и КМС по гиревому спорту. В 2019 году стал чемпионом кубка NBC в категории свыше 92 кг, в 2020 году – чемпионом России WFF. Несколько раз был призёром Кубка Москвы и Московской области, чемпионата России.\n" +
                        "Алексей анализирует желания клиента, чтобы детально сформировать для него план питания и тренировок.\n" +
                        "\n" +
                        "«Только полное соблюдение режима питания, тренировок и восстановления приведёт к успеху»",
                    reviews: [
                        {
                            time: "20.01.2022",
                            name: "Надежда",
                            desc: "Для меня это лучший тренер которого я знаю и видела! Он помогает мне во всем проделали путь от новичка до уверенного любителя. Занимаюсь 2 раза в неделю.",
                        },
                        {
                            time: "20.01.2022",
                            name: "Надежда",
                            desc: "Для меня это лучший тренер которого я знаю и видела! Он помогает мне во всем проделали путь от новичка до уверенного любителя. Занимаюсь 2 раза в неделю.",
                        },
                    ],
                },
            ],
            teacherView: {
                firstName: "ГЕРМАН",
                fullName: "ВОЛКОВ ГЕРМАН",
                category: "Персональный тренер",
                img: GermanPersonalImage,
                desc: "Герман – профессиональный футболист, окончивший училище олимпийского резерва в Донецке. После получения юридического образования вернулся в спорт и активно занялся тренерской деятельностью, параллельно повышая свою квалификацию на профильных курсах. С 2015 года является инструктором групповых программ и персональным тренером. Больше двух лет курировал групповые программы. Герман – чемпион региональных соревнований по бодибилдингу, призёр различных соревнований по футболу, Crossfit, бодибилдингу и фитнесу. При работе с клиентом стремится создать дружественную атмосферу и уделять человеку много времени. Его уникальная методика включает в себя построение перспективного календарного плана, анализ всех параметров организма, тщательное слежение за динамикой изменений, учёт режима питания и восстановления, индивидуальный подход к тренировкам, поддержку и постоянный контакт с клиентом. «Моя цель – привести клиента к результату, который он хочет увидеть».",
                reviews: [
                    {
                        time: "20.01.2022",
                        name: "Надежда",
                        desc: "Для меня это лучший тренер которого я знаю и видела! Он помогает мне во всем проделали путь от новичка до уверенного любителя. Занимаюсь 2 раза в неделю.",
                    },
                    {
                        time: "20.01.2022",
                        name: "Надежда",
                        desc: "Для меня это лучший тренер которого я знаю и видела! Он помогает мне во всем проделали путь от новичка до уверенного любителя. Занимаюсь 2 раза в неделю.",
                    },
                ],
            },
            slickPage: 0,
            isLoading: true,
            isModalOpen: false,
            isAuth: false,
            userData: {},
            isModalNewReviewOpen: false,
            text: "",
        }
    }

    componentDidMount() {
        this.setState({
            teachers: this.props.content.employees,
            teacherView: this.props.content.employees[0],
            isLoading: false,
            isAuth: this.props.auth.isAuth,
            userData: this.props.auth.userData
        })
    }

    handleAddRecordToTeacher() {
        const {phone, email, name, last_name} = this.state.userData;
        this.CMSAPI.addItem("recordToTeacher", {
            numberPhone: phone,
            email: email,
            userName: name + " " + last_name,
            teather: {_id: this.state.teacherView._id},
        }).then(() => {
            this.setState({isModalOpen: false});
            sendMail("Новая персональная запись к трененру", `Запись к ${this.state.teacherView.name} ${this.state.teacherView.lastName}`,
                `
                    Новая персональная запись к трененру ${this.state.teacherView.name} ${this.state.teacherView.lastName} <br/>
                    Имя клиента: ${name + " " + last_name} <br/>
                    Номер телефона: ${phone}
                `);
        });
    }

    handleAddNewReview() {
        const {name, last_name} = this.state.userData;
        const newReview = {
            name: name + " " + last_name,
            datatime: moment().format("YYYY-MM-DD"),
            text: this.state.text,
        };
        let reviews = this.state.teacherView.reviews;
        reviews.push(newReview);
        this.CMSAPI.addItem("employees", {
            _id: this.state.teacherView._id,
            reviews: reviews,
        }).then(() => {
            this.setState({isModalOpen: false});
            sendMail("Новый отзыв о тренере", `Новый отзыв о тренере ${this.state.teacherView.name} ${this.state.teacherView.lastName}`,
                `
                    Новый отзыв о тренере ${this.state.teacherView.name} ${this.state.teacherView.lastName} <br/>
                    Имя клиента: ${name + " " + last_name} <br/>
                    Текст: ${this.state.text}
                `)
        });
    }

    handleChangeTeatherViewContent(content) {
        this.setState({teacherView: content});
    }

    render() {
        return <>
            <div className="block teacherBlock">
                {
                    !this.state.isLoading
                        ? <div className="list">
                            <button className="sliderControlButton" onClick={() => this.sliderBig.slickPrev()}><img
                                src={ArrowUpIcon} alt=""/></button>
                            <Slider {...this.setting} ref={slider => (this.sliderBig = slider)}>
                                {
                                    this.state.teachers.map((e, i) => {
                                        return <div className="teacherCardMini"
                                                    onClick={() => this.handleChangeTeatherViewContent(this.state.teachers[i])}>
                                            <img src={this.CMSAPI.getImageLink(e.photo?.path)} alt=""/>
                                            <p className="title">{e.name}</p>
                                        </div>
                                    })
                                }
                            </Slider>
                            <button className="sliderControlButton" onClick={() => this.sliderBig.slickNext()}><img
                                src={ArrowDownIcon} alt=""/></button>
                        </div>
                        : null
                }

                <div className="info">
                    <div className="teacherCardMax">
                        <img src={this.CMSAPI.getImageLink(this.state.teacherView.photo?.path)} alt=""/>
                        <div className="text">
                            <div className="main">
                                <div className="t">
                                    <p className="title"
                                       onClick={() => window.location.href = "/trainer?id=" + this.state.teacherView._id}>{this.state.teacherView.name} {this.state.teacherView.lastName}</p>
                                    <p className="miniTitle">Персональный тренер</p>
                                </div>
                            </div>
                            <div className="desc"
                                 dangerouslySetInnerHTML={{__html: this.state.teacherView.description}}/>
                            <div className="buttons">
                                <button className="orange" onClick={() => this.setState({isModalOpen: true})}>КО МНЕ НА
                                    ТРЕНИРОВКУ
                                </button>
                                <a href onClick={() => this.setState({isModalNewReviewOpen: true})} className="link">ОСТАВИТЬ
                                    ОТЗЫВ</a>
                            </div>
                        </div>
                    </div>
                    <div className="reviews">
                        {
                            this.state.teacherView.reviews.map((e, i) => {
                                return <div className="review">
                                    <div className="main">
                                        <p className="time">{e.datatime}</p>
                                        <p className="name">{e.name}</p>
                                    </div>
                                    <p className="desc">
                                        {e.text}
                                    </p>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="teacherBlockMobile">
                <p className="titleBig">ТРЕНЕРА</p>
                <Slider {...{
                    dots: false,
                    arrows: false,
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    beforeChange: (current, next) => {
                        this.setState({slickPage: next, teacherView: this.state.teachers[next]})
                    },
                }} ref={slider => (this.slider = slider)}>
                    {
                        this.state.teachers.map((e, i) => {
                            return <div className="teacherCardMini"
                                        onClick={() => this.handleChangeTeatherViewContent(this.state.teachers[i])}>
                                <img src={this.CMSAPI.getImageLink(e.photo?.path)} alt=""/>
                                <p className="title">{e.name}</p>
                            </div>
                        })
                    }
                </Slider>
                {/*<div className="sliderControl">*/}
                {/*    <div className="left" onClick={() => this.slider.slickPrev()}><img src={ArrowLeftIcon} alt=""/>*/}
                {/*    </div>*/}
                {/*    <div className="dots">*/}
                {/*        {*/}
                {/*            this.state.teachers.map((e, i) => {*/}
                {/*                return <span className={`dot ${this.state.slickPage === i ? "on" : ""}`}/>*/}
                {/*            })*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*    <div className="right" onClick={() => this.slider.slickNext()}><img src={ArrowRightIcon} alt=""/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <br/>
                <p className="titleBig">ОТЗЫВЫ</p>
                {
                    this.state.teacherView.reviews.map((e, i) => {
                        return <div className="review">
                            <div className="main">
                                <p className="time">{e.datatime}</p>
                                <p className="name">{e.name}</p>
                            </div>
                            <p className="desc">
                                {e.text}
                            </p>
                        </div>
                    })
                }
                <button className="orange">ОСТАВИТЬ ОТЗЫВ</button>
            </div>
            <div className="modal" style={{display: this.state.isModalOpen ? "flex" : "none"}}
                 onClick={e => (e.currentTarget === e.target) && this.setState({isModalOpen: false})}
            >
                <div className="content">
                    {
                        this.state.isAuth
                            ? <>
                                <p className="title">Вы желаете записаться
                                    к {this.state.teacherView.name} {this.state.teacherView.lastName}?</p>
                                <button className="close" onClick={() => this.handleAddRecordToTeacher()}>Да</button>
                                <button className="close" onClick={() => this.setState({isModalOpen: false})}>Нет</button>
                            </>
                            : <>
                                <p className="title">Для записи необходимо авторизоваться</p>
                                <button className="close"
                                        onClick={() => this.props.router.navigate("/loginReg")}>Вход\Регистрация
                                </button>
                                <button className="close" onClick={() => this.setState({isModalOpen: false})}>Закрыть
                                </button>
                            </>
                    }
                </div>
            </div>
            <div className="modal" style={{display: this.state.isModalNewReviewOpen ? "flex" : "none"}}
                 onClick={e => (e.currentTarget === e.target) && this.setState({isModalNewReviewOpen: false})}
            >
                <div className="content">
                    {
                        this.state.isAuth
                            ? <>
                                <p className="title">Новый отзыв
                                    для {this.state.teacherView.name} {this.state.teacherView.lastName}?</p>
                                <textarea cols={10} rows={5} placeholder="Ваш отзыв..." onChange={(e) => this.setState({text: e.target.value})}/>
                                <button className="close" onClick={() => {
                                    this.handleAddNewReview();
                                    this.setState({isModalNewReviewOpen: false});
                                }}>Да
                                </button>
                                <button className="close" onClick={() => this.setState({isModalNewReviewOpen: false})}>Нет
                                </button>
                            </>
                            : <>
                                <p className="title">Для написания отзыва необходимо авторизоваться</p>
                                <button className="close"
                                        onClick={() => this.props.router.navigate("/loginReg")}>Вход\Регистрация
                                </button>
                                <button className="close"
                                        onClick={() => this.setState({isModalNewReviewOpen: false})}>Закрыть
                                </button>
                            </>
                    }
                </div>
            </div>
        </>;
    }
}

export default hookForClassComponent(TeacherBlock);