import React from "react";
import SelectComponent from "react-select";

const Select = (props) => {
    const customStyles = {
        singleValue: (base) => ({
            ...base,
            color: "#FFF",
        }),
        valueContainer: (base) => ({
            ...base,
            padding: 0,
        }),
        indicatorSeparator: (base) => ({
            display: "none",
        }),
        indicatorsContainer: (base) => ({
            padding: 0,
        }),
        dropdownIndicator: (base) => ({
            padding: 0,
            // marginLeft: "2.5px",
            transform: "scale(.7)",
        }),
        control: (base, state) => ({
            // ...base,
            background: "#22232F",
            color: "#FFF",
            fontWeight: "700",
            fontSize: "12px",
            borderRadius: "4px",
            padding: "5px",
            minHeight: 0,
            border: "none",
            display: "flex",
        }),
        menu: base => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            bottom: "4px",
            // kill the gap
            marginTop: 0,
            color: "#FFF",
            backgroundColor: "rgba(9, 10, 14, 1)",
        }),
        option: base => ({
            ...base,
            backgroundColor: "rgba(9, 10, 14, 1)",
            color: "#FFF",
            fontWeight: "700",
            fontSize: "12px",
            padding: "7.5px",
            ":active": {
                backgroundColor: "rgb(55,61,86)",
            },
            ":hover": {
                backgroundColor: "rgb(25,27,36)",
            },
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0,
            color: "#FFF",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            bottom: "4px",
        })
    };

    return <SelectComponent options={props.options}
                            defaultValue={props.options[1]}
                            styles={customStyles}
                            className="selectLang"/>
};

export default Select;