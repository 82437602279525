import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ReactComponent as Whell} from "./result.svg";
import ChestImg from "../../../Images/Icons/Chest.png";
import CMS from "../../../Api/CMS";
import User from "../../../Api/User";
import moment from "moment";
import {sendMail} from "../../../Api/SMTP";
import * as d3 from "d3";
import {useSelector} from "react-redux";

const Fortuna = () => {
    const CMSAPI = new CMS();
    const UserApi = new User();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState({});

    const [topPosItems, setTopPosItems] = useState(0);
    const [leftPosItems, setLeftPosItems] = useState(0);
    const [wItems, setWItems] = useState(0);
    const [currentWidth, setCurrentWidth] = useState(window.screen.width);

    useEffect(() => {
        if (isLoading) {
            UserApi.getAuthUserData(localStorage.getItem("userToken")).then((d) => {
                if (!d.data.result) {
                    navigate("/");
                } else {
                    setUserData(d.data.data);
                    setIsLoading(false);
                    let mobilePhoneUser = d.data.data.phone;
                    const R = document.querySelector(".whell svg circle").getBoundingClientRect().width;
                    setTopPosItems((document.querySelector(".whell svg circle").getBoundingClientRect().top));
                    setLeftPosItems((document.querySelector(".whell svg circle").getBoundingClientRect().left));
                    setWItems(document.querySelector(".whell svg circle").getBoundingClientRect().width);
                    CMSAPI.queryContentByFilter("winnersInFortune", {numberPhone: mobilePhoneUser.replace("+", "\+")}).then((data) => {
                        const lastTime = moment.unix(data.data[data.data.length - 1]._created);
                        if (!lastTime.add(30, "days").isBefore(moment())) {
                            setModalBlockShow(true);
                        }
                    });
                }
            }).catch(() => {
                navigate("/");
            });
        }

    });

    useEffect(() => {
        let currW = window.screen.width;
        const interval = setInterval(() => {
            if (window.screen.width !== currW) {
                window.scrollTo(0, 0);
                setCurrentWidth(window.screen.width);
                currW = window.screen.width;
                setTopPosItems((document.querySelector(".whell svg circle").getBoundingClientRect().top));
                setLeftPosItems((document.querySelector(".whell svg circle").getBoundingClientRect().left));
                setWItems(document.querySelector(".whell svg circle").getBoundingClientRect().width);
                console.log({
                    wItems,
                    leftPosItems,
                    topPosItems,
                    topPosE: document.querySelector(".whell .svgItems").getBoundingClientRect().top,
                    topPosR: document.querySelector(".whell svg circle").getBoundingClientRect().top,
                })
                console.log(window.screen.width);
                console.log(currentWidth);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const displayNameItemList = useSelector(state => state.content.itemsForFortune)

    const [n, setN] = useState(null);
    const [displayNameItem, setDisplayNameItem] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [modalBlockShow, setModalBlockShow] = useState(false);

    const getRndInteger = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const rotateWhell = () => {
        const index = getRndInteger(0, 7);
        setN(index);
        setDisplayNameItem(displayNameItemList[index].name);
        CMSAPI.addItem("winnersInFortune", {
            numberPhone: userData.phone,
            item: displayNameItemList[index].name,
        }).then(() => {
            sendMail("Выигрыш в фортуне", `Выигрыш в фортуне - ${displayNameItemList[index].name}`,
                `
                    Выигрыш в фортуне - ${displayNameItemList[index].name} <br/>
                    Имя клиента: ${userData.name + " " + userData.last_name} <br/>
                    Номер телефона: ${userData.phone}
                `)
        });
        setInterval(() => setModalShow(true), 7000);
    };

    return <div className={`whell rotate n${n}`}>
        <Whell/>
        <div className="svgItems" style={{
            position: "absolute",
            top: topPosItems + "px",
            left: leftPosItems + "px",
            width: wItems + "px",
            height: wItems + "px",
            padding: 0,
            background: "transparent",
        }}>
            {
                displayNameItemList.map((item, index) => {
                    return <div style={{width: `calc(${(wItems / 2)}px*0.6)`,}} className="t" i={index}>{item.name}</div>
                })
            }
        </div>
        <div className="text">
            <p className="title">Крути колесо</p>
            <p className="miniTitle">Испытай свою удачу на колесе фартуны</p>
            <button className="orange" onClick={rotateWhell}>Крутить колесо</button>
        </div>
        <div className={`winnerModal ${modalShow ? "show" : ""}`}>
            <div className="img">
                <img src={ChestImg} alt=""/>
            </div>
            <div className="text">
                <p className="title">Выиграли</p>
                <p className="nameItem">{displayNameItem}</p>
                <p className="desc">Подойди на ресепшен и скажи ,что выв выйграли и скажете номер телефона</p>
                <button className="primary" onClick={() => navigate("/")}>На главную</button>
            </div>
        </div>
        <div className={`winnerModal ${modalBlockShow ? "show" : ""}`}>
            <div className="img">
                <img src={ChestImg} alt=""/>
            </div>
            <div className="text">
                <p className="title">Вы уже крутили колесо</p>
                <p className="desc">Попробуйте в следующем месяце</p>
                <button className="primary" onClick={() => navigate("/")}>На главную</button>
            </div>
        </div>
    </div>
};

export default Fortuna;