import {createSlice} from "@reduxjs/toolkit";

export const contentSlice = createSlice({
    name: "content",
    initialState: {
        event: {},
        promotion: {},
        promotions: [{}],
        contacts: {},
        seoArray: [{}],
        contentForMainPage: {},
        interactiveInfo: [{}],
        plans: [{}],
        employees: [{}],
        miniBlockInfo: [{}],
        itemsForFortune: [{}],
        isLoading: true,
        isError: false,
    },
    reducers: {
        loadContent: (state, action) => {
            const {event, promotion, promotions, contacts, seoArray, contentForMainPage, interactiveInfo, plans, employees, miniBlockInfo, itemsForFortune} = action.payload;
            state.event = event;
            state.promotion = promotion;
            state.promotions = promotions;
            state.contacts = contacts;
            state.seoArray = seoArray;
            state.contentForMainPage = contentForMainPage;
            state.interactiveInfo = interactiveInfo;
            state.plans = plans;
            state.employees = employees;
            state.miniBlockInfo = miniBlockInfo;
            state.itemsForFortune = itemsForFortune;
            state.isLoading = false;
        },
    },
});

export const {loadContent} = contentSlice.actions;

export default contentSlice.reducer;