import React from "react";
import CMS from "../Api/CMS";
import DocumentMeta from "react-document-meta";
import Frame from "./Frame.svg";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const TeachersPage = () => {
    const CMSAPI = new CMS();
    const seo = useSelector(state => state.content.seoArray.find(seoItem => seoItem.pageTag === "teachers"));
    const trainers = useSelector(state => state.content.employees);

    return <DocumentMeta {...{
        title: seo.title,
        description: seo.description,
        meta: {
            charset: "utf-8",
            name: {
                keywords: seo.tags,
            },
        },
    }}>
        <div className="inPage">
            <div className="centerList">
                {
                    trainers
                        ? <div className="trainersList">
                            {
                                trainers.map((t, key) => <div key={key} className="trainerItem">
                                    {/*<img className="frame"  onClick={() => window.location.href = `/trainer?id=${t._id}`} src={Frame} alt=""/>*/}
                                    <img onClick={() => window.location.href = `/trainer?id=${t._id}`} src={CMSAPI.getImageLink(t.photo ? t.photo.path : t.photo.path)} alt={t.name}/>
                                    {/*<div className="image" style={{background: `url(${CMSAPI.getImageLink(t.photo ? t.photo.path : t.photo.path)})`, backgroundSize: "cover"}}></div>*/}
                                    <p className="title" onClick={() => window.location.href = `/trainer?id=${t._id}`}>{t.name}</p>
                                    <Link to={`/trainer?id=${t._id}`} className="link">подробнее</Link>
                                </div>)
                            }
                        </div>
                        : <p>null</p>
                }
            </div>
        </div>
    </DocumentMeta>
};

export default TeachersPage;