import React from "react";
import Plan from "../../Molecules/Plan/Plan";
import Slider from "react-slick";
import ArrowLeftIcon from "../../../Images/Icons/ArrowLeft.svg";
import ArrowRightIcon from "../../../Images/Icons/ArrowRight.svg";
import CMS from "../../../Api/CMS";
import User from "../../../Api/User";
import {hookForClassComponent} from "../../../Store/hookForClassComponent";

class PlansBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            slickPage: 0,
            isLoading: true,
            plans: [{}],
            isAuth: false,
            userData: {},
            isModalLoginShow: false,
            isModalMoreInfoShow: false,
            dataModalMoreInfo: [{}],
        };
    }

    CMSAPI = new CMS();
    UserApi = new User();

    componentDidMount() {
        this.setState({
            plans: this.props.content.plans,
            isLoading: false,
            isAuth: this.props.auth.isAuth,
            userData: this.props.auth.userData
        });
    }

    showModalMoreInfo(data) {
        this.setState({
            isModalMoreInfoShow: true,
            dataModalMoreInfo: data,
        })
    }

    render() {
        return <div className="block">
            {
                !this.state.isLoading
                    ? <>
                        <p className="title">АБОНЕМЕНТЫ</p>
                        <div className="planRow">
                            {
                                !this.state.isLoading
                                    ? this.state.plans.map((e) => {
                                        return <Plan time={e.duration} description={e.decsription} dayCost={e.costDay}
                                                     fullCost={e.costFull} userData={this.state.userData} oldDayCost={e.oldCostDay} oldFullCost={e.oldCostFull}
                                                     isClickable={this.state.isAuth} onClick={this.state.isAuth ? () => {
                                        } : () => {
                                            this.setState({isModalLoginShow: true})
                                        }}/>
                                    })
                                    : null
                            }
                        </div>
                        <div className="planRowMobile">
                            <Slider {...{
                                dots: false,
                                arrows: false,
                                infinite: true,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                beforeChange: (current, next) => this.setState({slickPage: next}),
                            }} ref={slider => (this.slider = slider)}>
                                {
                                    this.state.plans.map((e, i) => {
                                        return <Plan time={e.duration.toUpperCase()} description={e.description}
                                                     dayCost={e.costDay} fullCost={e.costFull}
                                                     oldDayCost={e.oldCostDay} oldFullCost={e.oldCostFull}
                                                     userData={this.state.userData} isClickable={this.state.isAuth}
                                                     onClick={this.state.isAuth ? () => {
                                                     } : () => {
                                                         this.setState({isModalLoginShow: true})
                                                     }}/>
                                    })
                                }
                            </Slider>
                            <div className="sliderControl">
                                <div className="left" onClick={() => this.slider.slickPrev()}><img src={ArrowLeftIcon}
                                                                                                   alt=""/></div>
                                <div className="dots">
                                    <span className={`dot ${this.state.slickPage === 0 ? "on" : ""}`}/>
                                    <span className={`dot ${this.state.slickPage === 1 ? "on" : ""}`}/>
                                    <span className={`dot ${this.state.slickPage === 2 ? "on" : ""}`}/>
                                    <span className={`dot ${this.state.slickPage === 3 ? "on" : ""}`}/>
                                </div>
                                <div className="right" onClick={() => this.slider.slickNext()}><img src={ArrowRightIcon}
                                                                                                    alt=""/></div>
                            </div>
                        </div>
                    </>
                    : null
            }
            <div className="modal" style={{display: this.state.isModalLoginShow ? "flex" : "none"}}
                 onClick={e => (e.currentTarget === e.target) && this.setState({isModalLoginShow: false})}
            >
                <div className="content">
                    <p className="title">Для приобретения необходимо авторизоваться</p>
                    <button className="close" onClick={() => this.props.router.navigate("/loginReg")}>Вход\Регистрация
                    </button>
                    <button className="close" onClick={() => this.setState({isModalLoginShow: false})}>Закрыть</button>
                </div>
            </div>
            <div className="modal" style={{display: this.state.isModalMoreInfoShow ? "flex" : "none"}}
                 onClick={e => (e.currentTarget === e.target) && this.setState({isModalMoreInfoShow: false})}
            >
                <div className="content">
                    <p className="title">{this.state.dataModalMoreInfo.duration}</p>
                    {/*<p>{this.state.dataModalMoreInfo.costDay} {this.state.dataModalMoreInfo.costFull}</p>*/}
                    <button className="close" onClick={() => this.setState({isModalMoreShowInfo: false})}>Закрыть
                    </button>
                </div>
            </div>
        </div>;
    }
}

export default hookForClassComponent(PlansBlock);