import React, {useEffect, useMemo, useState} from "react";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderProgressBar from "../Components/Molecules/SliderProgressBar/SliderProgressBar";

import MapImage from "../Images/Map.png";

import {ReactComponent as WhatsAppImage} from "../Images/Social/WhatsApp.svg";
import {ReactComponent as TelegramImage} from "../Images/Social/Telegram.svg";
import {ReactComponent as VKImage} from "../Images/Social/VK.svg";
import TeacherBlock from "../Components/PageParts/TeacherBlock/TeacherBlock";
import AboutBlock from "../Components/PageParts/AboutBlock/AboutBlock";
import HiddenPhoneNumber from "../Components/Molecules/Contacts/HiddenPhoneNumber/HiddenPhoneNumber";
import {ReactComponent as OnlineSvgImage} from "../Images/Icons/Online.svg";
import ClubStatic from "../Api/ClubStatic";
import PlansBlock from "../Components/PageParts/PlansBlock/PlansBlock";

import CMS from "../Api/CMS";
import User from "../Api/User";
import DocumentMeta from "react-document-meta";
import {useSelector} from "react-redux";

const MainPageDuble = () => {
    const CMSAPI = useMemo(() => new CMS(), []);

    const [heightPage, setHeightPage] = useState(window.innerHeight - 100);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
    const progressBarPositions = [10, 10.001, 70, 100];
    const [activeIndexSlider, setActiveIndexSlider] = useState(0);

    const [isModalPromotionOpen, setIsModalPromotionOpen] = useState(false);
    const {isAuth, userData} = useSelector(state => state.auth);

    const [isLoading, setIsLoading] = useState(true);
    const mainInfo = useSelector(state => state.content.contacts);
    const event = useSelector(state => state.content.event);
    const weekInfos = event.weekInfos;
    const promotion = useSelector(state => state.content.promotion);
    const seo = useSelector(state => state.content.seoArray.find(seoItem => seoItem.pageTag === "mainPageDuble"));

    useEffect(() => {
        if (heightPage !== (window.innerHeight - 100)) {
            if (window.innerWidth <= 600) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
            setHeightPage(window.innerHeight - 100);
        }

        if (isLoading) {
            setIsLoading(false);
            setInterval(() => {
                setActiveIndexSlider(activeIndexSlider === 0 ? 1 : 0);
            }, 100);
        }
    }, [heightPage, isLoading, activeIndexSlider]);

    const clubStatic = new ClubStatic();

    const [clubWorkload, setClubWorkload] = useState(0);

    useEffect(() => {
        let clubWorkloadTimeout = setTimeout(() => {
            clubStatic.getClubWorkload("a79874c9-91a4-11e9-9a3e-6c2b598ba92b").then((d) => setClubWorkload(d.data.data.count));
        }, 200);
        let clubWorkloadInterval = setInterval(() => {
            clubStatic.getClubWorkload("a79874c9-91a4-11e9-9a3e-6c2b598ba92b").then((d) => setClubWorkload(d.data.data.count));
        }, 60000);

        return () => {
            clearInterval(clubWorkloadInterval);
            clearTimeout(clubWorkloadTimeout);
        };
    });

    const handlePromotionPurchase = (item, cost, type) => {
        window.location.href = `https://спорт-макс.рф/payment.php?title=${encodeURIComponent(item)}&summ=${cost}.00&email=${encodeURIComponent(userData.email)}&phone=${encodeURIComponent(userData.phone)}&type=${type}`;
    };

    return <DocumentMeta {...{
        title: seo.title,
        description: seo.description,
        meta: {
            charset: "utf-8",
            name: {
                keywords: seo.tags,
            },
        },
    }}>
        <div className="inPage">
            <div className="swiper-slide">
                <div className="item" style={{
                    backgroundImage: `url(${CMSAPI.getImageLink(event.bigImage?.path)})`,
                }}>
                    <div className="info" style={{height: isMobile ? heightPage - 50 : heightPage}}>
                        <div className="mobileSecondHeader">
                            <div className="social">
                                <a className="whatsAppSvg" href={mainInfo.whatsapp}><WhatsAppImage/></a>
                                <a className="telegramSvg" href={mainInfo.Telegram}><TelegramImage/></a>
                                <a className="vkImage" href={mainInfo.vk}><VKImage/></a>
                            </div>
                            <div className={`online ${clubWorkload === 0 ? "off" : null}`}>
                                <OnlineSvgImage/>
                                <div className="text">
                                    <p className="count">{clubWorkload} человек</p>
                                    <p className="info">сейчас в клубе</p>
                                </div>
                            </div>
                        </div>
                        <p className="title">{event.titleFirstStr}</p>
                        <p className="miniTitle">{event.titleSecontStr}</p>
                        <p className="text" dangerouslySetInnerHTML={{__html: event.text}}/>
                        <button style={{zIndex: "10000"}} className="orange"
                                onClick={() => setIsModalPromotionOpen(true)}>{event.buttonText}</button>
                    </div>
                </div>
            </div>
            <SliderProgressBar progress={progressBarPositions[activeIndexSlider]} weekInfos={weekInfos}
                               sliderImage={CMSAPI.getImageLink(event.sliderImage?.path)}
                               sliderAlign={event.sliderAlign}/>
            <PlansBlock/>
            <TeacherBlock heightPage={heightPage}/>
            <AboutBlock heightPage={heightPage} mainInfo={mainInfo}/>
            <div className="block contacts">
                <div className="text">
                    <p className="title">КОНТАКТЫ</p>
                    <HiddenPhoneNumber number={mainInfo.phone ? mainInfo.phone : ""}/>
                    <a href={`mailto:${mainInfo.email}`} className="link">{mainInfo.email}</a>
                    <a href={mainInfo.mapLink} className="link">{mainInfo.address}</a>
                    <div className="social">
                        <a className="whatsAppSvg" href={mainInfo.whatsapp}><WhatsAppImage/></a>
                        <a className="telegramSvg" href={mainInfo.Telegram}><TelegramImage/></a>
                        <a className="vkImage" href={mainInfo.vk}><VKImage/></a>
                    </div>
                </div>
                <div className="map">
                    <img src={MapImage} alt="" onClick={() => window.location.href = mainInfo.mapLink}/>
                </div>
            </div>
            <div className="modal" style={{display: isModalPromotionOpen ? "flex" : "none", zIndex: "100000"}}
                 onClick={e => (e.currentTarget === e.target) && setIsModalPromotionOpen(false)}
            >
                <div className="content">
                    {
                        isAuth
                            ? <>
                                <p className="title">{promotion.title}</p>
                                <p className="text">{promotion.description}</p>
                                <p className="text">Цена: {promotion.cost} P.</p>
                                <button className="close"
                                        onClick={() => handlePromotionPurchase(promotion.title, promotion.cost, "promotion")}>Приобрести
                                </button>
                                <button className="close" onClick={() => setIsModalPromotionOpen(false)}>Закрыть</button>
                            </>
                            : <>
                                <p className="title">{promotion.title}</p>
                                <p className="text">{promotion.description}</p>
                                <p className="text">Цена: {promotion.cost} P.</p>
                                <p className="text">Для участия в акции необходимо авторизоваться</p>
                                <button className="close"
                                        onClick={() => window.location.href = "/loginReg"}>Вход\Регистрация
                                </button>
                                <button className="close" onClick={() => setIsModalPromotionOpen(false)}>Закрыть
                                </button>
                            </>
                    }
                </div>
            </div>
        </div>
    </DocumentMeta>
};

export default MainPageDuble;