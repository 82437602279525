import React from "react";
import HiddenPhoneNumber from "../Components/Molecules/Contacts/HiddenPhoneNumber/HiddenPhoneNumber";
import {ReactComponent as WhatsAppImage} from "../Images/Social/WhatsApp.svg";
import {ReactComponent as TelegramImage} from "../Images/Social/Telegram.svg";
import {ReactComponent as VKImage} from "../Images/Social/VK.svg";
import MapImage from "../Images/Map.png";
import DocumentMeta from "react-document-meta";
import {useSelector} from "react-redux";

const ContactsPage = () => {
    const mainInfo = useSelector(state => state.content.contacts);
    const seo = useSelector(state => state.content.seoArray.find(seoItem => seoItem.pageTag === "contacts"));

    return <DocumentMeta {...{
        title: seo.title,
        description: seo.description,
        meta: {
            charset: "utf-8",
            name: {
                keywords: seo.tags,
            },
        },
    }}>
        <div className="inPage">
            <div className="block contacts">
                <div className="text">
                    <p className="title">КОНТАКТЫ</p>
                    <HiddenPhoneNumber number={mainInfo.phone ? mainInfo.phone : ""}/>
                    <a href={`mailto:${mainInfo.email}`} className="link">{mainInfo.email}</a>
                    <a href={mainInfo.mapLink} className="link">{mainInfo.address}</a>
                    <div className="social">
                        <a className="whatsAppSvg" href={mainInfo.whatsapp}><WhatsAppImage/></a>
                        <a className="telegramSvg" href={mainInfo.Telegram}><TelegramImage/></a>
                        <a className="vkImage" href={mainInfo.vk}><VKImage/></a>
                    </div>
                </div>
                <div className="map">
                    <img src={MapImage} alt="" onClick={() => window.location.href = mainInfo.mapLink}/>
                </div>
            </div>
        </div>
    </DocumentMeta>
};

export default ContactsPage;