import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import HeaderComponent from "../Components/PageParts/Header/Header";
import CMS from "../Api/CMS";
import User from "../Api/User";
import {useDispatch, useSelector} from "react-redux";
import {login, logout} from "../Store/authSlice";
import {loadContent} from "../Store/contentSlice";
import {ReactComponent as Whell} from "../Components/PageParts/Fortuna/result.svg";

const IndexPage = () => {
    const CMSAPI = new CMS();
    const UserApi = new User();
    const dispatch = useDispatch();

    const isLoadingAuth = useSelector(state => state.auth.isLoading);
    const isLoadingContent = useSelector(state => state.content.isLoading);
    const [isLoadingAuthLocal, setIsLoadingAuthLocal] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [mainInfo, setMainInfo] = useState({});
    const [isCheckingClientRow, setIsCheckingClientRow] = useState(true);
    const [currentPath, setCurrentPath] = useState(window.location.href);

    useEffect(() => {
        console.log("updatePageState");
        console.log(currentPath + " " + window.location.href)

        if (isLoading) {
            CMSAPI.queryContentFirst("contacts").then((data) => {
                setIsLoading(false);
                setMainInfo(data.data);
            });
        }
        if (isLoadingContent) {
            CMSAPI.queryContentFirst("contacts").then((data) => {
                CMSAPI.queryContentAll("seo").then((seo) => {
                    CMSAPI.getCurrentEvent().then((event) => {
                        CMSAPI.queryContentById("promotions", data.data.activePromotion._id).then((promotion) => {
                            CMSAPI.queryContentById("contentForMainPage", data.data.activeContentForMainPage._id).then((contentForMainPage) => {
                                CMSAPI.queryContentFirst("interactiveInfo").then((interactiveInfo) => {
                                    CMSAPI.queryContentAll("plans").then((plans) => {
                                        CMSAPI.queryContentAll("employees").then((employees) => {
                                            CMSAPI.queryContentAll("promotions").then(promotions => {
                                                CMSAPI.queryContentAll("miniBlockInfo").then((miniBlockInfo) => {
                                                    CMSAPI.queryContentAll("itemsForFortune").then((itemsForFortune) => {
                                                        dispatch(loadContent({
                                                            event: event.data,
                                                            promotion: promotion.data,
                                                            promotions: promotions.data.reverse(),
                                                            contacts: data.data,
                                                            seoArray: seo.data,
                                                            contentForMainPage: contentForMainPage.data,
                                                            interactiveInfo: interactiveInfo.data,
                                                            plans: plans.data,
                                                            employees: employees.data,
                                                            miniBlockInfo: miniBlockInfo.data,
                                                            itemsForFortune: itemsForFortune.data,
                                                        }));
                                                    });
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        }
        if (!localStorage.getItem("userToken")) {
            dispatch(logout());
        } else if (isLoadingAuth && isLoadingAuthLocal) {
            setIsLoadingAuthLocal(false);
            UserApi.getAuthUserData(localStorage.getItem("userToken")).then((user) => {
                if (!user.data.result) {
                    localStorage.removeItem("userToken");
                } else {
                    // if (isCheckingClientRow) {
                    //     setIsCheckingClientRow(false);
                    //     CMSAPI.queryContentByFilter("clients", {phone: user.data.data.phone.replace("+", "\+")}).then((clients) => {
                    //         console.log(clients.data);
                    //         if (clients.data.length === 0) {
                    //             console.log("client not found");
                    //             setTimeout(() => CMSAPI.addItem("clients", {
                    //                 phone: user.data.data.phone.replace("+", "\+"),
                    //                 email: user.data.data.email,
                    //                 name: user.data.data.name + " " + user.data.data.second_name,
                    //             }), 1000);
                    //         }
                    //     });
                    // }
                    CMSAPI.queryContentByFilter("recordToTeacher", {numberPhone: user.data.data.phone}).then((d) => {
                        if (d.data.length > 0) {
                            console.log(d);
                            CMSAPI.queryContentById("employees", d.data[d.data.length - 1].teather._id).then((d) => {
                                if (d.data) {
                                    console.log(d.data);
                                    const currentTrainer = {
                                        name: d.data.name,
                                        lastName: d.data.lastName,
                                    };
                                    UserApi.getAuthUserBookings(localStorage.getItem("userToken")).then((d) => {
                                        const userBookingsData = d.data.data;
                                        let statusLengths = Object.assign({
                                            not_arrived: 0,
                                            arrived: 0,
                                            canceled: 0,
                                        }, {});
                                        d.data.data.find((b) => (b.arrival_status === "not_arrived" ? statusLengths.not_arrived++ : null));
                                        d.data.data.find((b) => (b.arrival_status === "arrived" ? statusLengths.arrived++ : null));
                                        d.data.data.find((b) => (b.arrival_status === "canceled" ? statusLengths.canceled++ : null));

                                        dispatch(login({
                                            token: localStorage.getItem("userToken"),
                                            userData: user.data.data,
                                            currentTrainer,
                                            userBookings: {
                                                data: userBookingsData,
                                                statusLengths,
                                            },
                                        }));
                                    });
                                } else {

                                }
                            }).catch(() => {
                                UserApi.getAuthUserBookings(localStorage.getItem("userToken")).then((d) => {
                                    const userBookingsData = d.data.data;
                                    let statusLengths = Object.assign({
                                        not_arrived: 0,
                                        arrived: 0,
                                        canceled: 0,
                                    }, {});
                                    d.data.data.find((b) => (b.arrival_status === "not_arrived" ? statusLengths.not_arrived++ : null));
                                    d.data.data.find((b) => (b.arrival_status === "arrived" ? statusLengths.arrived++ : null));
                                    d.data.data.find((b) => (b.arrival_status === "canceled" ? statusLengths.canceled++ : null));

                                    dispatch(login({
                                        token: localStorage.getItem("userToken"),
                                        userData: user.data.data,
                                        currentTrainer: {},
                                        userBookings: {
                                            data: userBookingsData,
                                            statusLengths,
                                        },
                                    }));
                                });
                            });
                        } else {
                            UserApi.getAuthUserBookings(localStorage.getItem("userToken")).then((d) => {
                                const userBookingsData = d.data.data;
                                let statusLengths = Object.assign({
                                    not_arrived: 0,
                                    arrived: 0,
                                    canceled: 0,
                                }, {});
                                d.data.data.find((b) => (b.arrival_status === "not_arrived" ? statusLengths.not_arrived++ : null));
                                d.data.data.find((b) => (b.arrival_status === "arrived" ? statusLengths.arrived++ : null));
                                d.data.data.find((b) => (b.arrival_status === "canceled" ? statusLengths.canceled++ : null));
                                console.log({token: localStorage.getItem("userToken"),
                                    userData: user.data.data,
                                    currentTrainer: {},
                                    userBookings: {
                                        data: userBookingsData,
                                        statusLengths,
                                    },});
                                dispatch(login({
                                    token: localStorage.getItem("userToken"),
                                    userData: user.data.data,
                                    currentTrainer: {},
                                    userBookings: {
                                        data: userBookingsData,
                                        statusLengths,
                                    },
                                }));
                            });
                        }
                    });
                }
            }).catch(() => {
                dispatch(logout());
            });
        }
    });

    setInterval(() => {
        setCurrentPath(window.location.href);
    }, 500);

    return <div className="page">
        {
            !isLoadingContent && !isLoadingAuth
                ? <>
                    <HeaderComponent mainInfo={mainInfo}/>
                    <Outlet/>
                    {
                        currentPath.includes("/lk") || currentPath.includes("/groupTimes")
                            ? null
                            : <div className="mobileBottomPanel">
                                <div className="fortuneBtn">
                                    <button className="orange" onClick={() => window.location.href = "/fortuna"}>
                                        <Whell/>
                                        <div className="p">
                                            <p className="title">КОЛЕСО ФОРТУНЫ</p>
                                            <p className="text">Получи подарок</p>
                                        </div>
                                    </button>
                                </div>
                                <div className="lk" onClick={() => window.location.href = "/lk"}>ЛИЧНЫЙ КАБИНЕТ</div>
                            </div>
                    }

                </>
                : null
        }
    </div>
};

export default IndexPage;