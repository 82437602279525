import {createSlice} from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        isAuth: false,
        token: "",
        userData: {
            name: "L",
            second_name: "L",
            phone: "",
            email: "",
        },
        currentTrainer: {},
        userBookings: {
            data: [{}],
            statusLengths: {
                not_arrived: 0,
                arrived: 0,
                canceled: 0,
            },
        },
        isLoading: true,
    },
    reducers: {
        login: (state, action) => {
            console.log(action.payload);
            const {token, userData, currentTrainer, userBookings} = action.payload;
            state.token = token;
            state.userData = userData;
            state.currentTrainer = currentTrainer;
            state.userBookings = userBookings;
            state.isAuth = true;
            state.isLoading = false;
        },
        logout: state => {
            state.token = "";
            state.userData = {};
            state.isAuth = false;
            state.isLoading = false;
        },
        refresh: state => {
            state.isLoading = true;
        },
    },
});

export const {login, logout, refresh} = authSlice.actions;

export default authSlice.reducer;