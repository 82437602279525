import React from "react";
import PlansBlock from "../Components/PageParts/PlansBlock/PlansBlock";
import DocumentMeta from "react-document-meta";
import {useSelector} from "react-redux";

const PlansPage = () => {
    const seo = useSelector(state => state.content.seoArray.find(seoItem => seoItem.pageTag === "plans"));

    return <DocumentMeta {...{
        title: seo.title,
        description: seo.description,
        meta: {
            charset: "utf-8",
            name: {
                keywords: seo.tags,
            },
        },
    }}>
        <div className="inPage">
            <PlansBlock/>
        </div>
    </DocumentMeta>
};

export default PlansPage;