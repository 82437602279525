import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import User from "../../Api/User";
import PuffLoader from "react-spinners/PuffLoader";
import moment from "moment";
import {Swiper, SwiperSlide, useSwiper} from "swiper/react";

import PaperImg from "../../Images/Icons/Paper.png";
import CupImg from "../../Images/Icons/Cup.png";
import FortuneImg from "../../Images/Icons/Fortune.png";

import CMS from "../../Api/CMS";
import DocumentMeta from "react-document-meta";
import {useDispatch, useSelector} from "react-redux";
import {refresh} from "../../Store/authSlice";

const exampleDayItems = [
    "10 приседаний",
    "10 пресс",
    "10 подтягиваний",
    "10 прыжков",
];

const LkSwiperTitle = ({index}) => {
    const swiper = useSwiper();

    return <div className="headerSw">
        <button className="buttonSw" onClick={() => swiper.slideNext()}>{"<"}</button>
        <p className="titleIn">{index} НЕДЕЛЯ</p>
        <button className="buttonSw" onClick={() => swiper.slideNext()}>{">"}</button>
    </div>
};

const DayInWeekComponent = (props) => {
    return <div className="day">
        {
            props.items.map(e => {
                return <p>{e}</p>
            })
        }
    </div>
};

const WeekComponent = (props) => {
    return <div className={`panel week ${props.disable ? "disable" : ""}`}>
        <div className="content">
            <p className="title">{props.index} неделя</p>
            <DayInWeekComponent items={exampleDayItems}/>
            <DayInWeekComponent items={exampleDayItems}/>
            <DayInWeekComponent items={exampleDayItems}/>
            <DayInWeekComponent items={exampleDayItems}/>
            <DayInWeekComponent items={exampleDayItems}/>
            <DayInWeekComponent items={exampleDayItems}/>
            <DayInWeekComponent items={exampleDayItems}/>
        </div>
        {
            props.disable
                ? <div className="payBlock">
                    <button className="primary pay">
                        Получить пропуск
                    </button>
                    <p className="text">
                        Для выполнения задания нужно приобрести пропуск
                    </p>
                </div>
                : null
        }
    </div>
};

const StatItemComponent = ({
                               title, content, group = {}, openModal = () => {
    }
                           }) => {
    return <div className="item" onClick={() => openModal(group)}>
        <div className="left">
            <div className="img"></div>
            <p className="name">{title}</p>
        </div>
        <div className="right">
            <div className="content">{content}</div>
        </div>
    </div>
};

const StatComponent = (props) => {
    return <div className={`panel stat ${props.disable ? "disable" : ""}`}>
        <p className="title">{props.title}</p>
        <div className={`listStat ${props.disable ? "disable" : ""}`}>
            {props.children}
        </div>
        {
            props.disable
                ? <button className="primary pay">
                    Получить пропуск
                </button>
                : null
        }
    </div>
};

const LkPage = () => {
    const UserApi = new User();
    const navigate = useNavigate();
    const [indexSwiper, setIndexSwiper] = useState(1);
    const [isModalNotTurnOpen, setIsModalNotTurnOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({
        service: {
            title: "",
        },
        employee: {
            name: "",
            position: {
                title: "",
            },
        }
    });

    const CMSAPI = new CMS();
    const dispatch = useDispatch();
    const isLoadingAuth = useSelector(state => state.auth.isLoading);
    const isLoadingContent = useSelector(state => state.content.isLoading);

    const userData = useSelector(state => state.auth.userData);
    const isAuth = useSelector(state => state.auth.isAuth);
    const currentTrainer = useSelector(state => state.auth.currentTrainer);
    const userBookings = useSelector(state => state.auth.userBookings);

    const event = useSelector(state => state.content.event);

    const [seo, setSeo] = useState({
        title: "",
        description: "",
        tags: "",
    });

    console.log(`state: ${isAuth} & ${isLoadingAuth}`);

    if (!isAuth && !isLoadingAuth) {
        window.location.href = "/loginReg";
    }

    useEffect(() => {
        CMSAPI.queryContentByFilter("seo", {pageTag: "lk"}).then((data) => {
            setSeo(data.data[0]);
        });
        if (!isAuth && !isLoadingAuth) {
            navigate("/loginReg");
        }
    });

    const openModal = (data) => {
        setModalData(data);
        setIsModalOpen(true);
    };

    const bookingOut = (id) => {
        UserApi.bookingOutAuthUser(localStorage.getItem("userToken"), id).then(d => {
            setIsModalOpen(false);
            dispatch(refresh());
        });
    };

    return <DocumentMeta {...{
        title: seo.title,
        description: seo.description,
        meta: {
            charset: "utf-8",
            name: {
                keywords: seo.tags,
            },
        },
    }}>
        <div className="inPage">
            <div>
                {
                    isLoadingAuth
                        ? <div className="loader">
                            <PuffLoader
                                color={"#33ccff"}
                                size={50}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                        : null
                }
                {
                    !isLoadingContent && !isLoadingAuth
                        ? <div>
                            <div className="pageContent lk desktop">
                                <div className="panel authUser">
                                    <button className="bigPic" onClick={() => navigate("/groupTimes")}>
                                        <img src={PaperImg} alt=""/>
                                        <p>Расписание</p>
                                    </button>
                                    <button className="bigPic" onClick={() => setIsModalNotTurnOpen(true)}>
                                        <img src={CupImg} alt=""/>
                                        <p>Турниры</p>
                                    </button>
                                    <button className="bigPic" onClick={() => navigate("/fortuna")}>
                                        <img src={FortuneImg} alt=""/>
                                        <p>Фортуна</p>
                                    </button>
                                    <div className="stat">
                                        <p>Звание: <b>Новичок</b></p>
                                        <p>Бесплатные часы: <b>0 часов</b></p>
                                    </div>
                                    <div className="userInfo">
                                        <div className="text">
                                            <p className="name">{userData.last_name} {userData.name} {userData.second_name}</p>
                                            {
                                                currentTrainer
                                                    ? <>
                                                        <p>Ваш персональный тренер:</p>
                                                        <p><b>{currentTrainer.name} {currentTrainer.lastName}</b></p>
                                                    </>
                                                    : null
                                            }
                                        </div>
                                        <div className="img">
                                            {userData.name[0]}.{userData.second_name[0]}.
                                        </div>
                                    </div>
                                </div>
                                <div className="panelRow">
                                    <div className="panel promo"
                                         style={{
                                             background: `url(${CMSAPI.getImageLink(event.miniEventBlock?.image.path)})`,
                                             backgroundSize: "cover"
                                         }}>
                                        <div className="text">
                                            <p className="title">{event.miniEventBlock?.title}</p>
                                            <button className="orange"
                                                    onClick={() => navigate("/mainPageDuble")}>ПОДРОБНЕЕ
                                            </button>
                                        </div>
                                        <div className="img">
                                            <img src={CMSAPI.getImageLink(event.miniEventBlock?.imagePerson.path)} alt=""/>
                                        </div>
                                    </div>
                                    <StatComponent title={"Предстоящие тренировки"}>
                                        {
                                            userBookings.data.map(booking => (
                                                booking.arrival_status === "not_arrived" ?
                                                    <StatItemComponent group={booking} title={booking.service.title}
                                                                       content={moment(booking.start_date).format("DD.MM.YY HH:mm")}
                                                                       openModal={() => openModal(booking)}/> : null
                                            ))
                                        }
                                    </StatComponent>
                                    <StatComponent title="Отмененые тренировки">
                                        {
                                            userBookings.data.map(booking => (
                                                booking.arrival_status === "canceled" ?
                                                    <StatItemComponent group={booking} title={booking.service.title}
                                                                       content={moment(booking.start_date).format("DD.MM.YY HH:mm")}
                                                                       openModal={() => openModal(booking)}/> : null
                                            ))
                                        }
                                    </StatComponent>
                                </div>
                                <WeekComponent index="1" disable/>
                                <WeekComponent index="2" disable/>
                                <WeekComponent index="3" disable/>
                                <WeekComponent index="4" disable/>
                            </div>
                            <div className="pageContent lk mobile">
                                <div className="panel authUser">
                                    <div className="userInfo">
                                        <div className="text">
                                            <p className="name">{userData.last_name} {userData.name} {userData.second_name}</p>
                                            {
                                                currentTrainer
                                                    ? <>
                                                        <p>Ваш персональный тренер:</p>
                                                        <p><b>{currentTrainer.name} {currentTrainer.lastName}</b></p>
                                                    </>
                                                    : null
                                            }
                                        </div>
                                        <div className="img">
                                            {userData.name[0]}.{userData.second_name[0]}.
                                        </div>
                                    </div>
                                </div>
                                <div className="panel authUser buttons">
                                    <Swiper
                                        slidesPerView={"auto"}
                                        spaceBetween={30}
                                    >
                                        <SwiperSlide>
                                            <button className="bigPic" onClick={() => navigate("/groupTimes")}>
                                                <img src={PaperImg} alt=""/>
                                                <p>Расписание</p>
                                            </button>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <button className="bigPic" onClick={() => setIsModalNotTurnOpen(true)}>
                                                <img src={CupImg} alt=""/>
                                                <p>Турниры</p>
                                            </button>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <button className="bigPic" onClick={() => navigate("/fortuna")}>
                                                <img src={FortuneImg} alt=""/>
                                                <p>Фортуна</p>
                                            </button>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                                <div className="panel promo"
                                     style={{
                                         background: `url(${CMSAPI.getImageLink(event.miniEventBlock?.image.path)})`,
                                         backgroundSize: "cover"
                                     }}>
                                    <div className="text">
                                        <p className="title">{event.miniEventBlock?.title}</p>
                                        <button className="orange" onClick={() => navigate("/mainPageDuble")}>ПОДРОБНЕЕ
                                        </button>
                                    </div>
                                    <div className="img">
                                        <img src={CMSAPI.getImageLink(event.miniEventBlock?.imagePerson.path)} alt=""/>
                                    </div>
                                </div>
                                <p className="title">ЗАПИСЬ НА ГРУППОВЫЕ ТРЕНИРОВКИ</p>
                                <StatComponent title={""}>
                                    {
                                        userBookings.data.map(booking => (
                                            booking.arrival_status === "not_arrived" ?
                                                <StatItemComponent group={booking} title={booking.service.title}
                                                                   content={moment(booking.start_date).format("DD.MM.YY HH:mm")}
                                                                   openModal={() => openModal(booking)}/> : null
                                        ))
                                    }
                                </StatComponent>
                                <br/>
                                {/*<Swiper*/}
                                {/*    onRealIndexChange={swiper => setIndexSwiper(swiper.realIndex + 1)}*/}
                                {/*    navigation={{*/}
                                {/*        nextEl: ".nextSwBtn",*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <SwiperSlide>*/}
                                {/*        <LkSwiperTitle index={indexSwiper}/>*/}
                                {/*        <div className="dayFlexMobile">*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*        </div>*/}
                                {/*    </SwiperSlide>*/}
                                {/*    <SwiperSlide>*/}
                                {/*        <LkSwiperTitle index={indexSwiper}/>*/}
                                {/*        <div className="dayFlexMobile">*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*        </div>*/}
                                {/*    </SwiperSlide>*/}
                                {/*    <SwiperSlide>*/}
                                {/*        <LkSwiperTitle index={indexSwiper}/>*/}
                                {/*        <div className="dayFlexMobile">*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*        </div>*/}
                                {/*    </SwiperSlide>*/}
                                {/*    <SwiperSlide>*/}
                                {/*        <LkSwiperTitle index={indexSwiper}/>*/}
                                {/*        <div className="dayFlexMobile">*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*            <DayInWeekComponent items={exampleDayItems}/>*/}
                                {/*        </div>*/}
                                {/*    </SwiperSlide>*/}
                                {/*</Swiper>*/}
                            </div>
                        </div>
                        : null
                }
            </div>
            <div className="modal" style={{display: isModalNotTurnOpen ? "flex" : "none"}}
                 onClick={e => (e.currentTarget === e.target) && setIsModalNotTurnOpen(false)}
            >
                <div className="content">
                    <p className="title">Доступных турниров пока нет</p>
                    <button className="close" onClick={() => setIsModalNotTurnOpen(false)}>Закрыть</button>
                </div>
            </div>
            <div className="modal" style={{display: isModalOpen ? "flex" : "none"}}
                 onClick={e => (e.currentTarget === e.target) && setIsModalOpen(false)}>
                <div className="content">
                    <p className="title">{modalData.service.title}</p>
                    <p>{modalData.service.description}</p>
                    <br/>
                    <p className="time">
                        {moment(modalData.start_date).format("dd DD.MM.YYYY")} {moment(modalData.start_date).format("HH:mm")} - {moment(modalData.end_date).format("HH:mm")}
                        <p className="duration">{modalData.duration} мин.</p>
                    </p>
                    <hr/>
                    <div className="teacher">
                        <p className="employee">{modalData.employee.name}</p>
                        <p className="employeeDesc">{modalData.employee.position.title}</p>
                    </div>
                    {
                        modalData.arrival_status === "not_arrived"
                            ? <button className="booking" onClick={() => bookingOut(modalData.appointment_id)}>Отменить
                                бронь</button>
                            : null
                    }
                    {
                        modalData.arrival_status === "arrived"
                            ? <p className="time">Занятие пройдено</p>
                            : null
                    }
                    {
                        modalData.arrival_status === "canceled"
                            ? <p className="time">Бронирование отменено</p>
                            : null
                    }
                    <button className="close" onClick={() => setIsModalOpen(false)}>Закрыть</button>
                </div>
            </div>
        </div>
    </DocumentMeta>
};

export default LkPage;