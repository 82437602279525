import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import React from "react";

export const hookForClassComponent = (Component) => {
    return (props) => {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        const content = useSelector(state => state.content);
        const auth = useSelector(state => state.auth);
        return (
            <Component
                {...props}
                router={{location, navigate, params}}
                content={content}
                auth={auth}
            />
        );
    };
};